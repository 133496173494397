@import '../../../scss/index.scss';

.dataFile{
    
    .row-data-file{
        @include flexbox(100%, row, flex-start, unset, wrap);
        div:first-child{
            margin-top: 2px;
        }
        
        div{
            margin-right: 0.5rem;
        }
    }
}
