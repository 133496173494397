@import '../../../scss/index.scss';

.form-contractType{

    .modal-content-wrapper{

        .control-wrapper{
            @include flexbox(100%, row, space-between, unset, nowrap);

            .flex-container{
                width: 68%;

                .control-input {
                    width: 100%;
                }
            }

            .flex-container:first-of-type{
                width: 30%;

                .input-container{
                    min-width: unset;
                } 
            }
        }
    }
}