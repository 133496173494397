@import "../../../scss/index.scss";

.maestros-data-table {
    @include insideScrollBehavior();

    .p-datatable-wrapper {
        .p-datatable-table {
            // font-size: 14px;

            tr > td {
                background: $white !important;
            }
        }
    }

    display: flex;
    flex-direction: column;
    // border-bottom-left-radius: $border-radius-general;
    // border-bottom-right-radius: $border-radius-general;
    // border: 1px solid $gray;

    .p-datatable-wrapper {
        @include insideScrollBehavior();
        flex-grow: 1;

        &::-webkit-scrollbar {
            height: 6px;
        }

        .p-datatable-table {
            // border-bottom-left-radius: $border-radius-general;
            // border-bottom-right-radius: $border-radius-general;
            // background: $white;
            font-size: 14px;

            &.p-datatable-scrollable-table {
                //z-index cuando se hace scroll
                .p-datatable-thead {
                    z-index: 11;
                }
            }

            .p-datatable-thead {
                // border-width: 1px;
                // margin-bottom: 5px;
                // font-weight: 600;

                .p-sortable-column {
                    background: $white;
                }

                tr > th {
                    background: $white;
                    font-weight: 600;
                    padding: 0.5rem 1rem;
                }

                tr > th[data-p-highlight="true"] {
                    color: $black-color;
                }
            }

            .p-datatable-tbody {
                tr:nth-child(odd) > td {
                    background-color: $light-grayish-blue !important;
                }

                tr > td {
                    word-break: break-all;
                    padding: 0.5rem 1rem;

                    input[type="text"] {
                        background-color: $bg-input-edit !important;
                    }

                    &.row-delete {
                        //padding: 1rem 0.3rem 1rem 1rem;
                        width: 0px;
                    }

                    &.row-edit {
                        padding: 0.5rem 1rem 0.5rem 0rem;
                        width: 6rem;
                        button {
                            float: right;
                        }
                    }

                    .p-row-editor-save:focus-visible,
                    .p-row-editor-cancel:focus-visible {
                        box-shadow: unset;
                    }

                    &.p-frozen-column {
                        background-color: $rose;
                        z-index: 1;
                    }

                    .items-column {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 6px;

                        .item {
                            border: 0.5px solid $safflower-red;
                            padding: 4px;
                            font-size: 12px;
                            border-radius: 8px;
                            background-color: $white;
                        }
                    }
                }

                //New tooltip dentro de tablas
                .column-tooltip {
                    position: relative;
                }
            }
        }
    }

    .p-datatable-selectable:hover {
        background: $white;
        outline: unset;
    }
}

.roll-title {
    font-weight: 400 !important;
    font-size: 12px;
    background-color: inherit !important;
}

// .editRow {
//     background-color: $edit-color !important;
// }

// .read-only {
//     background-color: $white !important;
// }

.cell-table {
    padding: 0.2rem 0.5rem !important;
    border-right: 1px solid $gray !important;
}

.cell-input {
    padding: 0.1rem 0.3rem !important;
    width: 100%;
    font-size: 0.8rem !important;
}

//TREE TABLE dejar de momento en table general eliminar despuesde recisar todo el html
.style-tree-table {
    font-size: 0.8rem;
    word-break: break-all;
    .p-treetable {
        .p-treetable-thead > tr > th {
            padding: 0.5rem;
            transition: none;
        }

        .p-treetable-tbody {
            tr > td {
                padding: 0.7rem;
                transition: none;
                .p-row-toggler:focus-visible {
                    box-shadow: unset;
                }

                .p-treetable-toggler:focus {
                    box-shadow: 0 0 0 0.2rem $silver-light;
                }
            }
            tr:focus {
                outline: unset;
            }
        }

        & button {
            margin-left: 0;
        }
    }
}
