@import '../../../scss/index.scss';

//DadesCenter
.center-data {
    width: 100%;
   
   /* &.is-general-data{
        padding: 0 1rem;
    }*/
    
    &__container{
        &--actions{
            @include flexbox(100%, row, flex-end, center);
            .edit-icon{
                display: inline-block;
                width: 25px;
                cursor: pointer;
                margin-bottom:-25px;
                z-index: 1;
            } 
        }
        &--data{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;
            padding-top: 1.6rem;
            @media screen and (min-width: $media-large-land) {
                grid-template-columns: repeat(6, 1fr);
            }

            /*
            .data-info-container{
                @include flexbox(100%, column, flex-start, flex-start);
                padding-top: .5rem;
                @media screen and (min-width: $media-medium) {
                    width: calc(100% - 270px);
                    flex-direction: row;
                }
                &.is-general-data__container{
                    padding-top: 0;
                }
                .data-info-wrapper{
                    @include flexbox(100%, row, flex-start, flex-start);
    
                    .flex-container{
                        @include flexbox(100%, row, flex-start, center);
                        margin-bottom: .5rem;
                        font-size: 14px;
                        @media screen and (min-width: $media-medium) {
                            width: 50%;
                        }
                        .text-label{
                            font-weight: bold;
                            width: auto;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }*/
        }
    }
}