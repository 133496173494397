@import "../../../scss/index.scss";

//CoursModal.jsx
.cours-modal {
    .datepicker-wrapper {
        @include flexbox(100%, row, space-between, center, wrap);
        .flex-container {
            @include flexbox(100%, row, flex-start, center, wrap);
            &:first-of-type {
                margin-bottom: 1rem;
            }

            .control-label {
                width: 120px;
                @media screen and (min-width: $media-medium) {
                    width: 90px;
                }
            }

            .control-input {
                width: calc(100% - 120px);
                @media screen and (min-width: $media-medium) {
                    width: calc(100% - 90px);
                }
                & > span {
                    width: 100%;
                }
            }
        }
    }
}
