@import "../../../scss/index.scss";

.header-title-container {
    display: flex;
    flex-grow: 1;

    .title {
        &__container {
            text-align: left;
        }
        /*estilo para el subtitulo del header*/
        &__subtitle {
            padding-right: 1rem;
        }
    }
}
