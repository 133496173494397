@import "../../scss/index.scss";

$processmap-sidebar-width: 60px;
$processmap-grid-gap: 8px;

.processmap-container {
    display: grid;
    height: 100%;
    width: 100%;
    gap: $processmap-grid-gap;
    grid-template-columns: $processmap-sidebar-width calc(
            100% - $processmap-sidebar-width - $processmap-grid-gap
        );

    .processmap-container-sidebar {
        grid: 1;
        border-radius: $border-radius;
        background: $white;
    }
    .processmap-container-content {
        grid: 2;
    }
}

.p-splitter {
    border: 0px none !important;
}

.p-splitter-panel {
    // height: 100%;
}

.p-panel {
    width: 100%;

    .p-panel-header {
        padding: 8px 20px;
        vertical-align: middle;
        .p-panel-title {
            display: flex;
            align-items: center;
            height: 33px;
        }
    }

    .p-panel-content,
    .p-toggleable-content {
        margin: 0;
        height: calc(
            (100% + $processmap-grid-gap + 1px) - $processmap-sidebar-width
        );

        .p-panel-content {
            height: 100%;
        }
    }
}

.p-splitter {
    color: blue;
}
.p-splitter-gutter {
    background: #eee;
    margin: 2px;
    padding: 1px;
}
.p-splitter-gutter-handle {
    background: #ccc;
    padding: 2px;
}
.p-splitter-gutter-handle:hover {
    padding: 4px;
}
.p-splitter-gutter-handle[aria-orientation="horizontal"] {
    width: 60px;
}
.p-splitter-gutter-handle[aria-orientation="vertical"] {
    height: 60px;
}
