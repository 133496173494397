@import "../../../scss/index.scss";

.center-course-routes {
    @if ($app-debug) {
        // :is(*):after {
        //     @extend .app-debug-label;
        // }
    }
    display: inline-flex;
    flex-direction: column;
    background-color: $white;
    // height: 100%;
    border-radius: $border-radius-general;
    padding: 0.5rem;
    gap: 0.5rem;
    flex-grow: 1;

    @media screen and (max-width: $media-x-small) {
        padding-bottom: 140px;
    }

    @media screen and (min-width: $media-medium) {
        flex-direction: row;
    }

    .menu-aside {
        min-width: 250px;
        @if ($app-debug) {
            *:first-child:after {
                @extend .app-debug-label;
                left: 0 !important;
                top: 500px !important;
            }
            background: cyan;
        }
    }

    .section-routes {
        width: 100%;
        // height: calc(100vh - 180px);
    }
}
