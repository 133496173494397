@import '../../scss/index.scss';

.form-user{
    .modal-content-wrapper{
        
        .control-wrapper{
            @include flexbox(100%, column, flex-start, flex-start, wrap);
            margin-bottom: 0.5rem;
        }
        
        .flex-container{
            @include flexbox(100%, column, flex-start, flex-start, wrap);
            &:first-of-type{

                .control-input{
                    margin-bottom: 0;
                }
            }
            
            .control-label{
                width: 100%;   
            }

            .control-input{
                width: 100%;
                
                & > span{
                    width: 100%;
                }
            }
            
        }
    
    }
}
