@import '../../../scss/index.scss';

.p-checkbox {
    opacity: 1;

    .p-checkbox-box {
        background-color: $bg-input-edit;
        border-color: unset;
        border: unset;

        &.p-focus{
            border-color: $safflower-red;
            box-shadow: unset;
        }
    }
    &:not(.p-checkbox-disabled) .p-checkbox-box:hover{
        border-color: $safflower-red;
    }
    &:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        box-shadow: unset;
        border-color: unset;
    }
}

.p-checkbox-disabled{
    
    .p-checkbox-box {
        background-color: transparent;
    }
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
    box-shadow: unset !important;
    border-color: unset !important;
}