@import "../../../../../scss/index.scss";

.class-room-and-students{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-grow: 1;
    overflow-y: auto;

    .buget-data-table{
        max-height: calc(100vh - 255px);
        
        @media only screen and (min-width: $media-xx-large) {
            max-height: calc(100vh - 270px);
        }
    }
}