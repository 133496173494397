@import '../../../../scss/index.scss';

.foundation-team-container{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 66px);
    height: 83vh;
    @media only screen and (min-width: $media-x-large) {
        min-height: calc(100vh - 78px);
    }

    .team__container{
        flex-grow: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;

        /*.row-wrapper{
            .sub-title{
                width: 100%;
                margin-top: .5rem;
            }
        }*/

        .team-sub-container{
            display: flex;
            flex-grow: 1;
            overflow: auto;
            
            .posts{
                width: 45%;
                @media only screen and (min-width: $media-large) {
                    width: 30%;
                }
            }

            .team-wrapper-container{
                flex-grow: 1;
                overflow: auto;
                display: flex;
                flex-direction: column;
                width: 55%;
                @media only screen and (min-width: $media-large) {
                    width: 70%;
                }

                .team-header{
                    @include flexbox(100%, row, space-between, center);
                    padding-bottom: 0.5rem;

                    .title__container{
                        font-weight: 400;
                    }
                    
                }
            }

            .card-items{
               // height: 61vh!important;
                @media screen and (min-width: $media-xx-large) {
                    //height: 75vh;
                } 
                
                .items-list{
                    padding-top: unset;
                }
            }
            
        }
    }
}



