@import "../../../scss/index.scss";

.btn {
    padding: 5px;
    border-radius: $border-radius-general;
    cursor: pointer;
    border: none;
    color: $white;
    font-size: 14px;
    @include flexbox(auto, row, center, center, nowrap);

    [class^="icon-"] {
        color: $white;
        @include flexbox(inherit, row, center, center, nowrap);
    }

    .icon-left-wrapper .pi {
        margin-right: 0.5rem;
    }

    .icon-right-wrapper .pi {
        margin-left: 0.5rem;
    }

    &.with-border {
        box-shadow: inset 0px 0px 0px 2px $dark-shade-pink-red;
    }

    &.btn-export {
        padding: 3px 10px;
        font-size: 12px;
    }

    // Button red
    &.btn-red {
        background: $safflower-red;
        &__padding {
            &__5_8 {
                padding: 5px 8px;
            }
        }
    }

    // Button dark-red
    &__with-bg-type-1 {
        background: $safflower-red;
        color: $white;
        font-weight: 500;

        .pi {
            color: $white !important;
        }

        &__padding {
            &__8_0 {
                padding: 5px 0px;
            }
            &__0_10 {
                padding-right: 10px;
            }
            &__8_15 {
                padding: 5px 12px;
            }
            &__8_10 {
                padding: 5px 10px;
            }
        }
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
    }

    &__padding {
        &__8_0 {
            padding: 5px 0px;
        }
        &__0_10 {
            padding-right: 10px;
        }
        &__8_15 {
            padding: 5px 12px;
        }
        &__8_10 {
            padding: 5px 10px;
        }
    }
}

.button-white {
    position: relative;
    background: $white;
    color: $safflower-red;
    border-radius: $border-radius;
    cursor: pointer;
    border: $border-05-color;
    font-weight: 500;

    &.with-img > img {
        width: 2.7rem;
    }

    &__padding {
        &__8_0 {
            padding: 5px 0px;
        }
        &__0_10 {
            padding-right: 10px;
        }
        &__8_15 {
            padding: 5px 12px;
        }
        &__8_10 {
            padding: 5px 10px;
        }
    }
}

.btn-delete {
    background: $safflower-red;
    border-color: $safflower-red;
    color: $white;

    [class^="icon-"] i {
        color: $white !important;
    }
}

// datatable actions items
.btn-icon {
    width: 1.3rem;
    background: transparent;
    border: none;
    cursor: pointer;
}

// tooltip inside the table
.table-tooltip {
    @media screen and (min-width: $media-small) {
        .table-tooltip-context {
            position: absolute;
            min-width: 100px;
            min-height: 20px;
            background-color: $white;
            text-align: left;
            border-radius: $border-radius-5;
            border: $border-05-color;
            transition: ease all 0.1s;
            color: $safflower-red;
            z-index: 10;
            display: none;
            top: 0;
            right: 50px;
            font-size: 12px;
            padding: 1px 2px;
            line-height: normal;
            word-break: normal !important;
        }

        .table-tooltip-context::before {
            content: "";
            height: 0;
            right: -10px;
            width: 0;
            top: 12px;
            position: absolute;
            border: 8px solid transparent;
            border-right: 0;
            border-left: 8px solid $safflower-red;
            transition: ease all 0.1s;
        }

        &:hover .table-tooltip-context,
        .table-tooltip-context::before {
            visibility: visible;
            opacity: 1;
            display: block;
        }
    }
}
