@import "../../scss/index.scss";

$info-card-img-size: 35px;

.info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.5rem 0;
    border: 1px solid $body-color;
    box-shadow: $general-shadow;
    border-radius: $border-radius-general;

    &__img {
        width: $info-card-img-size;
        height: $info-card-img-size;
    }

    .title__subtitle {
        font-size: 14px;
        font-weight: 500;
    }

    &__description {
        font-size: 12px;
    }
}
