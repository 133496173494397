@import "../../../scss/index.scss";

$main-view-header-height: 50px;
.main-view-header {
    @if ($app-debug) {
        *:after {
            @extend .app-debug-label;
        }
    }

    min-height: $main-view-header-height;
    min-height: $main-view-header-height;

    .title {
    }

    .buttons {
        align-items: flex-end;
        display: inline-flex;
        @media screen and (max-width: $media-medium-land) {
            @if ($app-debug) {
                background: chocolate;
            }
        }
        button {
            margin-left: $app-component-spacer;
        }
    }

    @include flexbox(100%, row, space-between, center, nowrap);

    @media screen and (max-width: $media-small-land) {
        flex-wrap: wrap;
        .buttons {
            margin-top: $app-component-spacer;
            @include flexbox(100%, row, flex-end, center, wrap);
        }
    }
}
