@import '../../../scss/index.scss';
.form-subvention {
    
    .modal-content-wrapper {
        
        .control-wrapper {
            @include flexbox(100%, row, space-between, center, wrap);

            .flex-container {
                @media screen and (min-width: $media-medium) {
                    width: 48%;
                    flex-direction: column;
                    align-items: flex-start;
                }

                .control-input {
                    width: 100%;
                    
                }
                
            }
        }

        
    }
}
