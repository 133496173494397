@import '../../../../scss/index.scss';

.collective-agreements {
    .p-datatable {
         

        .p-row-editor-save, .p-row-editor-cancel {
            display: none;
        }

        .file-list li {
            padding: 0.5rem 0;
        }

        .file-list li * {
            display: block
        }
        
    }
}