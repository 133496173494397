@import '../../scss/index.scss';

.maestros{
    
    @include insideScrollBehavior();
    flex-grow: 1;

    .box-list{
        @include gridMediaBoxCard('noicons', 160px !important);
    } 
    
    .box-item{
        font-size: 1.1rem;
        letter-spacing: 1px;
    }
}