@import "../../../scss/index.scss";

//bugetTable

.buget-data-table {
    @if ($app-debug) {
        // :is(*):after {
        //     @extend .app-debug-label;
        // }
    }
    border-radius: $border-radius-general;
    /**scroll table**/
    // max-height: calc(100vh - 330px);

    // height: 100%;
    overflow: auto;

    // // Global Media Queries (3 sizes)
    // @media screen and (max-width: $media-x-small) {
    //     height: 100vh;
    // }
    // @media screen and (min-width: $media-x-small) {
    //     height: calc(100vh - 600px);
    // }
    // @media screen and (min-width: $media-small-land) {
    //     background: yellow !important;
    //     height: calc(100vh - 550px);
    // }
    // @media screen and (min-width: $media-medium) {
    //     height: calc(100vh - 350px);
    // }
    // @media only screen and (min-width: $media-large-land) {
    //     max-height: calc(100vh - 320px);
    // }

    // .p-component-overlay
    // .p-datatable-loading
    .p-datatable-loading-overlay {
        padding-top: 70px;
        background: rgba(255 255 255 / 0.6);
    }

    .p-datatable-wrapper {
        .p-datatable-table {
            // font-size: 12px;
            // font-weight: 400;

            .p-datatable-tbody {
                tr {
                    td {
                        input[type="text"] {
                            background-color: $white !important;
                            // padding: 0.1rem 0.3rem;
                            // font-size: 12px;
                        }
                    }
                    td:not(.editRow, .p-editable-column) {
                        &:first-child {
                            background: $body-color !important;
                        }
                    }
                }

                .editRow,
                .p-editable-column {
                    background-color: $edit-color !important;

                    .p-dropdown {
                        width: auto;
                        border: $border-05-color;
                        .p-inputtext {
                            // padding: 0.2rem 0.3rem;
                            // font-size: 12px;
                        }
                    }

                    .p-calendar {
                        width: auto;
                    }
                }

                .read-only {
                    background-color: $white !important;
                }
            }

            .p-datatable-tfoot {
                > tr > td {
                    padding: 0.5rem;
                    border: unset;
                    border-width: unset;
                    font-weight: 500;
                    color: $black-color;
                    background: $body-color;
                }
            }

            .cell-table {
                padding: 0.3rem 0.5rem !important;
                height: 40px;
            }

            .p-calendar {
                .p-inputtext {
                    border: 1px solid $safflower-red;
                }
            }
        }
    }
}
