@import '../../../scss/index.scss';

.form-center-team{

    .modal-content-wrapper{

        .control-wrapper{
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            
            .flex-container:first-child{
                flex-grow: 1;
            }
        }  
    }
}
