@import "./variables";

$app-main-header-height-small: 160px;
$app-main-header-height-medium: 67.11px;
$app-main-header-height-large: 78.14px;
$app-small: $media-x-small;
$app-large: $media-large-land;

@if ($app-debug) {
    * {
        // border: 1px solid blue;
    }
}

.app-debug-label {
    position: absolute;
    // pointer-events: none;

    font-family: $font-monospace;
    font-size: 12px;
    font-weight: 100;

    background: rgba(lime, 0.6);
    color: black;
    text-shadow: 0 1px 0 white;

    margin: 0;

    display: inline;
    white-space: nowrap;

    padding: 1px 6px;
    border: 1px solid black;
    top: 0;
    left: 0;
    content: attr(class);

    cursor: pointer;
    z-index: 0;
}
.app-debug-label:hover {
    z-index: 99999 !important;
    overflow: visible !important;
    background: rgba(lime, 1);
}

* {
    box-sizing: border-box;
}

#root {
    min-width: 320px !important;

    // min-height: 100vh;
    // height: 100vh;

    // @if ($app-debug) {
    //     @media screen and (max-width: $media-x-small) {
    //         // height: calc(100vh - $app-main-header-height-small) !important;
    //         background: silver;
    //     }
    //     @media screen and (min-width: $media-x-small) {
    //         // height: calc(100vh - $app-main-header-height-medium) !important;
    //         background: gray;
    //     }
    @media screen and (min-width: $media-large-land) {
        // height: calc(100vh - ($app-main-header-height-large + 16px)) !important;
        // background: blue;
    }
    // }
}

.text-monospace {
    font-family: $font-monospace;
    font-weight: 100;
}
