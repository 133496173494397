@import '../../../../../scss/index.scss';
 
.basic-staff-profile{
    .staff-info-card{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        
        @media only screen and (min-width: $media-small-land){
            grid-template-columns: repeat(5,1fr);
        }
        @media only screen and (min-width: $media-xx-large){
            grid-template-columns: repeat(8,1fr);
        }

        @include box-container-grid ();
        
        .flex-container{
            .input-container .input-control {
                font-size: 12px;
            }
            
            .p-dropdown {
                background-color: $white;
                opacity: 1;
                border:1px solid $gray;
                
            }
        }
        
    
    }
    
    .staff-info-card:nth-child(3){
        @media only screen and (min-width: $media-xx-large){
            grid-template-columns: repeat(14,1fr);
        }
        @media only screen and (max-width: $media-xx-large){
            grid-template-columns: repeat(8,1fr);
        }
        @media only screen and (max-width: $media-medium){
            grid-template-columns: repeat(4,1fr);
        }
    }
}
