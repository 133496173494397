@import '../../scss/index.scss';

.paginator-container {
    
    .p-disabled, .p-component:disabled {
        background-color: transparent;
    }

    .p-paginator {
        margin-top: 0.5rem;
        padding: 0 ;
        background: unset;
        
        button{
            margin: 0;
            padding: 0;
            height: 2rem;
            min-width: 2rem;
            margin-right: 0.3rem;
        }

        .p-paginator-pages {

            .p-paginator-page {
                color: $dark-shade-pink-red ;
                border: 1px solid $gray ;
                border-radius: $border-radius-general ;
                font-size: 14px;
                &:focus{
                    box-shadow: unset;
                }
                
                &:hover{
                    border-color:$safflower-red;
                    box-shadow: unset;
                }

                &.page-active {
                    background: $rose;
                }
            }
        }

        .p-dropdown{
            width: unset;
            height: 2rem;
            background-color: transparent;
            border:$border-05-color;
            
            .p-inputtext{
                padding: 0.34rem 0.55rem;
                padding-right: 0;
                color: $dark-shade-pink-red;
                background: transparent;
            }
        }
    } 
}