

//input switch
.p-inputswitch{

    .p-inputswitch-slider {
        box-shadow: unset;
        background: $gray;
    }

    &:not(.p-disabled):hover .p-inputswitch-slider {
        background: $color-disabled;
    }
}
    
.p-inputswitch-checked{

    .p-inputswitch-slider {
        background: $rose-medium;
    }

    &:not(.p-disabled):hover .p-inputswitch-slider {
        background: $rose-medium;
    }
}
