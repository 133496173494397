@import '../../../scss/index.scss';

.add-satff-form{

    .modal-content-wrapper{

        .content-wrapped{
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            
            .flex-container:first-child{
                flex-grow: 1;
            }
        }
    }
}