@import '../../../scss/index.scss';

.section-structure-wrapped{
    @include flexbox(100%, column, unset, unset, nowrap);
    height: 100%;
    gap: 0.5rem;

    .structure-header{
        @include flexbox(100%, row, space-between, center, nowrap);

        &__title{
            font-weight: 500;
            font-size: 14px;
        }

        &__buttons{
            display: flex;
            flex-wrap: nowrap;
            gap: 0.5rem;
        }
    }

    .context-structure{
        flex-grow: 1;
    }
}