@import "../../../scss/index.scss";

//EtapasList.jsx

.etapas{

    @include insideScrollBehavior();
    flex-grow: 1;

    .etapas-list{
        @include gridMediaBoxCard('icons', 100px);
        margin-bottom: 0;
        padding-top: 0.5rem;
        
        .box-item{
            @include boxContentComplete();
        }
    }
}
    

