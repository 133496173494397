@import "../../scss/index.scss";

.backoffice-container {
    .box-list {
        @include gridMediaBoxCard("noicons", 160px !important);

        padding: 0;

        .box-item-icon {
            padding: 0.5rem;
            font-size: 1.1rem;
            letter-spacing: 1px;
            background-color: $dark-shade-pink-red;
            border-color: $dark-shade-pink-red;
            text-align: center;
            transition: 0.1s ease-in-out all;

            .white-text {
                padding: 0.5rem;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                color: $white;
                height: 100%;
                width: 100%;

                .card-img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
}
