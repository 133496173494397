@import "../../scss/index.scss";

.box-item {
    @include flexbox(100%, column, center, center, nowrap);
    height: auto !important;
    min-height: 100px !important;
    background: rgb(238, 237, 231);
    background: $bg-gradient-card;
    border-radius: $border-radius-general;
    box-shadow: 0 6px 6px -6px $shadow-gray-color;
    position: relative;
    &:hover {
        box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.4);
        .red-text {
            box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.4);
            text-shadow: 0 0 0.2px;
        }
    }

    .box-content {
        @include flexbox(100%, column, center, center, nowrap);
        border-radius: $border-radius-general;
        border: 1.5px solid $safflower-red;
        height: 100%;
        text-align: center;
        transition: 0.1s ease-in-out all;
        position: relative;

        // Click on any area of the Card (not only the text)
        .box-content__text {
            padding: 0.5rem;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 100%;
            width: 100%;
        }

        & .red-text {
            border-radius: $border-radius-general;
            color: $safflower-red;
        }

        //Cards actions items
        .box-icon-action {
            width: 70px;
            height: 26px;
            border: $border-05-color;
            border-radius: 8px;
            background-color: $white;
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            bottom: -5px;
            right: 5px;
            padding: 10px 7px 5px;

            .button-item-box {
                cursor: pointer !important;
                width: 1.1rem;
            }
        }
    }

    /*BOX-ITEM CONFIRMAR*/
    @include boxContentComplete();

    &:hover .box-content__link {
        @media screen and (max-width: 1439px) {
            font-size: 13px;
        }
    }
}
