// TODO: Font needs to be downloaded, to avoid external downloads.
// Import only needed fonts (light, regular and bold, for example)
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import "../../scss/index.scss";
@import "./swal-alert.scss";
@import "./width.scss";
@import "./margin.scss";
@import "./prime-react/index.scss";

* {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif !important;
}

*:focus-visible,
button:focus-visible {
    outline-color: $color-disabled !important;
    box-shadow: unset !important;
    outline-offset: 1.5px !important;
    outline: 1.5px solid $color-disabled;
}

body {
    margin: 0;
    padding: 0;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(161, 161, 161, 0.6);
        background-color: $white;
        border-radius: $border-radius-general;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: $gray;
        height: 6px;
        border-radius: $border-radius-general;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(161, 161, 161, 0.6);
        border-radius: $border-radius-general;
    }
}

a {
    text-decoration: none;
    color: $a-color-text;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
p,
span {
    padding: 0;
    margin: 0;
}

// Container styles
.container {
    padding: $padding-container;

    &__without-padding-bottom {
        padding-bottom: 0;
    }

    &__without-padding-top {
        padding-top: 0;
    }

    &__with-background {
        background: $bg-container;

        // Calculated in main-layout
        // position: relative;
        // min-height: calc(100vh - 69px);

        // @media screen and (min-width: $media-xx-large) {
        //     min-height: calc(100vh - 80px);
        // }
    }

    &__with-legend {
        background: $bg-container;
    }
}

// Align center
.align-center {
    display: flex !important;
    align-items: center !important;
}

.disabled-text {
    color: #ccc;
}

// Title styles
.title {
    &__container {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        color: $safflower-red;
    }

    &__subtitle {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        color: $color-title-container;

        &__white {
            color: $white;
            font-weight: 300;
            margin: 0;
            padding: 0;
        }
    }

    &__red-subtitle {
        color: $safflower-red;
        font-size: 14px;
        font-weight: 300;
        margin: 0;
        padding: 0;
    }
}

// Text styles
.text {
    &__align {
        &__right {
            text-align: right;
        }

        &__center {
            text-align: center;
        }

        &__center-none {
            @media screen and (min-width: 1196px) {
                text-align: center;
            }
        }
    }

    &.font-size {
        font-size: 0.9rem;
    }

    &__wrap {
        word-wrap: break-word;
    }

    &.text-bold {
        font-weight: 600;
    }
}

.word-wrap {
    word-break: break-all;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// Restyle huge combos (in styled component do not work)
.huge-combo .Select__control {
    border: $border-05-color !important;
    height: 38px !important;
    border-radius: $border-radius-general !important;

    .Select__single-value {
        padding-bottom: 0 !important;
    }
}

.separator {
    border-bottom: 2px solid $white;
    padding-bottom: 10px;
}

// No item style
.no-teams-message {
    padding-left: 1rem;
    font-size: 14px;
    color: $shadow-gray-color;
}

.text-not-data,
.p-datatable-emptymessage {
    font-size: 12px;
    color: $shadow-gray-color;
}

// General position of flex container
.flex-container {
    display: flex;
    width: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: unset;
    flex-wrap: unset;
    align-content: normal;
    margin-bottom: 0.5rem;
}

.bg-structure-box {
    background-color: $body-color;
    border-radius: $border-radius-general;
    padding: 0.5rem;

    .inline-items {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 1rem;

        .flex-container:first-child {
            flex-grow: 1;
        }

        .flex-container:last-child {
            width: 24%;
        }
    }
}
