@import '../../../../scss/index.scss';

.management-positions{

    .p-datatable {
        .p-datatable-tbody{
            .p-inputtext{
                min-width: 70px;
            }
        }
    }
}

