@import '../../../scss/index.scss';

.form-pas {

    .modal-content-wrapper {

        .control-wrapper {

            .flex-container {

                .control-input {
                    width: 100%;
                }

            }
        }
    }
}