@import '../../../scss/index.scss';

.new-post-foundation-team{

    .modal-content-wrapper{

        .control-wrapper{

            .flex-container{
                @include flexbox(100%, column, flex-start, flex-start, wrap);
                
                .control-input{
                    width: 100%;
                }
                
            }
        }
        
        
    }
}
