@import '../../../scss/index.scss';

.search-component {
    width: 100%;

    @media screen and (min-width: $media-medium) {
        width: 100%;
        margin-bottom: 0;
    }

    .p-input-icon-left {
        position: relative;
        display: inline-block;

        i {
            position: absolute;
            top: 50%;
            left: 10px;
            margin-top: -0.4rem;
            font-size: 0.9rem;
        }

        .p-inputtext {
            margin: 0;
            padding: 5px 12px 5px 35px;
            border: $border-05-color;
            background: $bg-container;

            &:enabled:focus,
            &:enabled:hover {
                border-color: $safflower-red;
            }

            &::placeholder {
                color: $safflower-red;
            }
        }
    }
}