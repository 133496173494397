@import "./variables";

$datatable-border-width: 1px;
$datatable-font-size: 12px !important;
$datatable-font-weight: 400;
$datatable-edit-control-margin: -7px;

$datatable-debug: false;
$datatable-debug-margin: 8px;

.p-datatable {
    @if ($datatable-debug) or ($app-debug) {
        *:after {
            @extend .app-debug-label;
        }
        background: red !important;
        padding: $datatable-debug-margin;
    }

    font-size: $datatable-font-size;

    .p-component-overlay,
    .p-datatable-loading-overlay {
        background: rgba(255 255 255 / 0.6) !important;
        .p-icon {
            display: none !important;
        }
        transform: translateZ(1px);
        animation: fadeIn 4s;
    }

    .p-datatable-loading-overlay:after {
        content: url("../assets/imgs/logos/icono-logo-modal.svg");
        display: inline-block;
        width: 90px;
        height: 90px;
        text-align: center;
        line-height: 40px;
        font-size: 32px;
        animation: logo-flip 8s cubic-bezier(0, 1, 1, 1) infinite;
    }

    .p-datatable-wrapper {
        @if ($datatable-debug) or ($app-debug) {
            background: orange !important;
            padding: $datatable-debug-margin;
        }
        min-height: 120px;
        border: unset;
        border-radius: $border-radius-general;
        border-radius: $border-radius-general;

        .p-datatable-table {
            @if ($datatable-debug) or ($app-debug) {
                background: yellow !important;
                padding: $datatable-debug-margin;
            }

            .p-datatable-thead {
                tr {
                    th {
                        background: $body-color;
                        padding: 0.5rem;
                        font-size: $datatable-font-size;
                        font-weight: 600;
                    }
                    th.p-align-end {
                        .p-column-header-content {
                            display: flex;
                            text-align: right;
                            justify-content: flex-end;
                        }
                    }
                    th.disabled {
                        .p-column-header-content {
                            color: $gray;
                        }
                    }
                }
            }

            .p-datatable-tbody,
            .p-datatable-tfoot {
                @if ($datatable-debug) or ($app-debug) {
                    background: lightyellow !important;
                    padding: $datatable-debug-margin;
                    margin: $datatable-debug-margin;
                }

                tr > td.p-datatype-number,
                tr > td.p-datatype-number > div, /* This is for CalculHours.jsx, that contains bad design patter without editor */
                tr > td.p-datatype-number > span > input {
                    font-size: $datatable-font-size;
                    @extend .text-monospace;
                    text-align: right;
                }

                tr {
                    td.disabled {
                        color: $color-disabled !important;
                    }
                    td.none {
                        color: $color-disabled !important;
                        background: $bg-container !important;
                    }
                    .read-only {
                        background-color: $white !important;
                    }
                }

                tr {
                    td {
                        @if ($datatable-debug) or ($app-debug) {
                            background: lightyellow !important;
                            padding: $datatable-debug-margin !important;
                            margin: $datatable-debug-margin !important;
                        }
                        padding: 0.5rem;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        input {
                            font-size: $datatable-font-size;
                            background: $white;
                        }

                        .p-inputtext:not(.p-dropdown-label):active,
                        .p-inputnumber-input:active {
                            background: $white !important;
                        }
                        .p-inputtext:not(.p-dropdown-label):focus,
                        .p-inputnumber-input:focus {
                            border: $border-05-color;
                            background: $white !important;
                        }
                    }

                    .editRow,
                    .p-editable-column {
                        background-color: $edit-color;
                        border-right: 1px solid white;
                        cursor: pointer;

                        // Global input styles
                        .p-input,
                        .p-inputtext:not(.p-dropdown-label),
                        .p-inputnumber-input,
                        .p-dropdown {
                            height: 100% !important;
                            border: $border-05-color;
                            background: $white;
                            margin: $datatable-edit-control-margin !important;
                        }

                        .p-dropdown {
                            width: 100%;
                            border: $border-05-color;
                            margin: $datatable-edit-control-margin;
                            background-color: $white;
                            .p-dropdown-label {
                                background-color: $white;
                                font-size: $datatable-font-size;
                            }
                        }

                        .p-calendar {
                            margin: $datatable-edit-control-margin;
                            width: 100%;
                            .p-inputtext {
                                margin: $datatable-edit-control-margin 0
                                    $datatable-edit-control-margin
                                    $datatable-edit-control-margin !important;
                                border: 1px solid $safflower-red;
                                margin-right: 2px;
                            }
                            .p-datepicker-trigger {
                                height: 28px;
                                margin-top: $datatable-edit-control-margin;
                            }
                        }
                    }

                    .editRow.fixed,
                    .p-editable-column.fixed {
                        .p-inputnumber-input {
                            border-color: transparent;
                        }
                        .p-inputnumber-input:active {
                            background: $white !important;
                        }
                        .p-inputnumber-input:focus {
                            border: $border-05-color;
                            background: $white !important;
                        }
                    }

                    .p-align-end {
                        text-align: right;
                    }
                }
            }

            // Avoid input controller overflow footer
            .p-datatable-tfoot {
                z-index: 0;
            }
        }
    }
}

.p-dropdown-footer {
    display: flex;
    justify-content: flex-end;
    border-top: $border-1p-color !important;
    padding: $app-component-spacer;
}

// Avoid flickering on click
@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.p-datepicker {
    animation: show 0.1s ease-in;
    z-index: 99999;
}
