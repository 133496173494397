@import "../../scss/index.scss";

.profile-auth {
    position: relative;
    display: flex;
    justify-content: center;

    .profile-auth-wrapper {
        min-width: 640px;
        width: 60%;
        position: relative;

        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);

        .profile-auth-wrapper-info {
            display: flex;
            height: 39vh;
            @media only screen and (min-width: $media-large-land) {
                height: 29vh;
            }

            .profile-auth-wrapper-upload {
                padding: 1rem;
                border-radius: $border-radius-general;
                background-color: $white;
                box-shadow: $general-shadow;
                height: 100%;
                margin-right: 1rem;
                @include flexbox(30%, column, space-between, unset, unset);

                .profile-img {
                    height: 80%;
                    display: grid;
                    place-items: center;
                    border-radius: $border-radius-general;
                    background-color: $white;
                    overflow: hidden;

                    img {
                        max-width: 240px;
                        width: 70%;
                    }
                }
            }

            .data-profile {
                background-color: $white;
                padding: 1rem;
                border-radius: $border-radius-general;
                box-shadow: $general-shadow;
                width: 70%;

                .flex-container {
                    width: 100%;

                    .control-label {
                        font-size: 14px;
                        font-weight: 500;
                    }

                    .control-input {
                        .p-disabled {
                            border: unset;
                        }
                    }
                }
            }
        }

        .edit-icon {
            margin-bottom: -4rem;
            margin-right: 1rem;
        }
    }
}
