@import "../../scss/index.scss";

.header {
    @if ($app-debug) {
        *:after {
            @extend .app-debug-label;
        }
    }
    padding: 0.5rem 1rem;

    // Global Media Queries (3 sizes)
    @media screen and (max-width: $media-x-small) {
        height: $app-main-header-height-small;
    }
    @media screen and (min-width: $media-x-small) {
        height: $app-main-header-height-medium;
    }
    @media screen and (min-width: $media-large-land) {
        height: $app-main-header-height-large;
    }

    &-container {
        @include flexbox(100%, row, space-between, center, nowrap);

        &__logo {
            width: 160px;

            @media screen and (min-width: $media-large-land) {
                width: 200px;
            }
            &--link {
                min-width: 160px;
                width: 160px;
                max-width: 160px;
                display: inline-block;

                .logo-img {
                    height: auto;
                }

                @media screen and (min-width: $media-large-land) {
                    width: inherit;
                    min-width: inherit;
                    max-width: inherit;
                }
            }
        }
        &__icons {
            @include flexbox(100%, row, right, center);

            @media screen and (min-width: $media-medium) {
                //width: calc(100% - 250px);
                justify-content: flex-end;
            }

            .icon-wrapper {
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (min-width: $media-medium) {
                    width: 50px;
                }
                &__link {
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    @media screen and (min-width: $media-medium) {
                        width: 50px;
                    }
                }
                .profile-img {
                    border: 1.5px solid $safflower-red;
                    border-radius: 50%;
                    width: 39px !important;
                    height: 39px !important;
                    overflow: hidden;
                    display: grid;
                    place-items: center;
                    @media screen and (min-width: $media-medium) {
                        width: 41px !important;
                        height: 41px !important;
                    }

                    img {
                        width: 90%;
                    }
                    @media screen and (min-width: $media-small) {
                        &::after {
                            bottom: -14px;
                            z-index: 11;
                        }

                        &::before {
                            bottom: -5px !important;
                            z-index: 11;
                        }

                        &:hover::after {
                            bottom: -30px !important;
                            z-index: 11;
                        }

                        &:hover::before {
                            bottom: -10px !important;
                            z-index: 11;
                        }
                    }
                }

                .with-tooltip {
                    position: relative;
                    transition: 0.2s ease all;
                    height: 50px;
                    width: 50px;
                    @include tooltip();
                    @media screen and (min-width: $media-medium) {
                        height: 50px;
                        width: 50px;
                    }
                }
            }
        }
    }

    .logo-padding {
        padding-top: 1%;
    }
}
