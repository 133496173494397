@import '../../../scss/index.scss';

.course-tabs {
    @include flexbox(100%, column, unset, unset, wrap);
    height: 100%;
    flex-grow: 1;
    gap: 0.5rem;

    @include general-container();
    &__aside {
        .list {
            .item-list {
                // Default styling for active items
                &:not(.disabled)  {
                    color:$black-color;
                    outline: none;
                }
    
                // Disabled state styling
                &.disabled {
                    color:$color-disabled;
                    pointer-events: none;
                    cursor: not-allowed !important;
                }
            }
        }
    }

}

