@import "../../../scss/index.scss";

.spinner {
    transform: translateZ(1px);
    @include flexbox(100%, column, center, center);
    height: 100%;
    animation: fadeIn 4s;
}

.spinner:after {
    content: url("./../../../assets/imgs/logos/icono-logo-modal.svg");
    display: inline-block;
    width: 90px;
    height: 90px;
    text-align: center;
    line-height: 40px;
    font-size: 32px;
    animation: logo-flip 8s cubic-bezier(0, 1, 1, 1) infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes logo-flip {
    0% {
        animation-timing-function: cubic-bezier(1, 1, 1, 1);
    }

    100% {
        transform: rotateY(3600deg);
    }
}
