@import "../../scss/index.scss";

$app-main-common-view-debug: false;

.main-common-view {
    @if ($app-main-common-view-debug) {
        background: red !important;
    }
    padding: 0.5rem;
    position: relative;
    background: $bg-container;
    height: 100%;

    .box-container {
        display: flex;
        flex-direction: column;
        border-radius: $border-radius-general;
        min-height: calc(100vh - 83px);
        height: 83vh;
        @media screen and (min-width: $media-large-land) {
            min-height: calc(100vh - 94px);
            height: 88vh;
        }

        .component-data {
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0 0 0;
            flex-grow: 1;
            height: 100%;
            background: $bg-container;

            @include insideScrollBehavior();
        }
    }
}
