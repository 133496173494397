@import '../../../scss/index.scss';

.template-line-hour-stage-form{

    .modal-content-wrapper{
        
        .content-wrapped{
            display: flex;
            gap: 1rem;
        }
    }
}