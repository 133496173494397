@import "../../scss/index.scss";

.card-items {
    @include insideScrollBehavior();
    flex-grow: 1;

    .items-list {
        padding-top: 0.5rem;
        @include gridMediaBoxCard("icons", auto !important);
        margin-bottom: 0;

        .box-item {
            @include boxContentComplete();
        }
    }
}
