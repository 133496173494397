@import '../../../../../scss/index.scss';

.staff-profile-Ss{
    @include box-container-grid ();
    
    .flex-container{

        .input-container .input-control {
            font-size: 12px;
        }
    }
}