@import '../../../../scss/index.scss';

/* Delete AFTER REFACT.
.input-container {
    @include flexbox(auto, column, flex-start, flex-start);
    position: relative;
    padding-bottom: .5rem;
    min-width: 216px;
    
    .input-control {
        width: 100%;
        height: 37px;
        border: transparent;
        padding: 0.25em 0.6em;
        box-sizing: border-box;
        font-size: 14px;
        outline: none;
        border-radius: $border-radius-general;
        color: $black-color;
        background-color:$bg-input-edit;
        
        &.center{
            text-align: center;
        }

        &.left{
            text-align: left;
        }
        
        &:disabled {
            background-color: $white;
            color: $black-color;
            border:1px solid $gray;
        }

        &:focus { 
            border: 1px solid $gray;
            outline: none !important;
        }

        &:focus-visible {
            border: 1px solid $gray;
            outline: none !important;
        }

        &.bg-edit {
            border: none;
            background:$bg-input-edit;
        }

        &::-webkit-input-placeholder {
            color: $color-disabled !important;
            font-size: 14px;
        }
        &:-ms-input-placeholder {
            color: $color-disabled !important;
            font-size: 14px;
        }
        
        &::placeholder {
            color: $color-disabled !important;
            font-size: 14px;
        }
        
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px $white inset;
            background-color: transparent;
        }
    }

    //input with icon right-login page
    .p-password-input{
        text-align: center;

        &::placeholder {
            color: $color-disabled !important;
            font-size: 14px;
            text-align: center;
        }
    }

}  
*/

// inputtext
.p-inputtext {
    width: 100%;
    border: transparent;
    padding: 0.5em;
    box-sizing: border-box;
    font-size: 14px;
    outline: none;
    border-radius: $border-radius-general;
    color: $black-color;
    background-color:$bg-input-edit;
    text-overflow: ellipsis;

    &:enabled:focus {
        box-shadow: none ;
        border-color:$gray ;
    }

    &:enabled:hover {
        border-color:$gray ;
    }

    &::-webkit-input-placeholder {
        color: $text-placeholder-color;
        font-size: 14px;
    }
    &:-ms-input-placeholder {
        color: $text-placeholder-color;
        font-size: 14px;
    }
    
    &::placeholder {
        color: $text-placeholder-color;
        font-size: 14px;
    }
    
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $white inset;
        background-color: transparent;
    }
    
}

.p-inputnumber {
    width: 100%;

    .p-inputnumber-input{
        width: 100%;
    }
}
