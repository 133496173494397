@import '../../../scss/index.scss';

.common-component-header {
    
    .header-wrapper-content{
        position: relative;
        background-color: $white;
        border-radius: $border-radius-general;
        padding: 0.5rem;
        @include flexbox(100%, row, space-between, center, wrap);

        .left-aligned-part-of-header{
            display: flex;
            flex-grow: 1;
        }
    
        .title{
            &__container {
                text-align: left;
            }
            &__subtitle{
                padding: 0 1rem;
                font-size: 14px;
            }
        }

        .right-aligned-part-of-header{
            display: flex;
            justify-content: right;
            align-items: center;
            row-gap: 1em;
            
            @media only screen and (max-width: $media-small) {
                flex-wrap: wrap;
            }
            
            .search {
                width: auto;
            }
    
            .order-by-dropDown{
                margin-left: 0.5rem;
                font-size:14px;

                .p-inputtext {
                    padding: 5px;
                    background-color: $white;
                }
            }

            /*select btn order*/
            .p-dropdown{
                width: 120px;
                margin-left: 0.3rem;
                background-color: $white;
                border:$border-05-color;
            }
        }
    }
    

}