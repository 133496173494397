@import '../../scss/index.scss';

.import-file-modal {
        margin-top:0.5rem;

        .modal-content-wrapper{ 
            .flex-container{
                @include flexbox(100%, column, flex-start, flex-start, wrap);
                .control-label{
                    width: 100%; 
                }

                .control-input{
                    width: 100%;
                }

                //upload file btn
                input[type="file"]::file-selector-button {
                    color: $black-color;
                    padding: 0.2em 0.5em;
                    border: unset;
                    border-radius: 7px;
                    background-color: $edit-color;
                    border: 1px solid $gray;
                    cursor: pointer;
                    font-size: 14px;
                }

                .input-control:read-only {
                    background-color:$white;
                    color: $black-color;
                    font-size: 14px;
                }

                .input-control{
                    padding: 0.5rem;
                    border:$border-05-color ;
                }
            }
        }
    }
