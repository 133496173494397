@import '../../../scss/index.scss';

.p-disabled, .p-component:disabled {
    opacity: 0.8;
    background-color: $white;
    color: $black-color;
    border:1px solid $gray;
    border-radius: 10px;
}

//commun panel sugestion info
.p-password-panel{

    .p-password-info{
        font-size: 12px;
    }

    .p-password-meter{
        background-color: $hover-color;
        border-radius: $border-radius-general;
    }
}

/************btn substage data ?????*/
.p-button {
    border: 2px solid $safflower-red ;
    color: $safflower-red ;
    background: $white ;
    font-size: 1rem;
    margin-bottom: 0.5rem;

    .p-button-label {
        font-weight: 500;
    }
    &:focus{
        box-shadow:unset ;
    }
}

// esto no esta factorizado 
.p-selectbutton {
    .p-button {
        border: 2px solid $safflower-red;
        color: $safflower-red;

        .p-button-label {
            font-weight: 500;
        }
    }
    .p-button:focus {
        box-shadow: unset;
    }
    .p-button:not(.p-disabled):not(.p-highlight):hover {
        background: $silver-light;     
    }
}

//factorizar despues de encontrar donde se usa
.p-buttonset .p-button:first-of-type {
    border-right:unset!important;
    border-top-right-radius: none !important;
    border-bottom-right-radius: unset !important;
}
.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
    border-right:unset!important;
}
.p-buttonset .p-button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}








