@import '../../../scss/index.scss';

.p-treeselect {
    width: 100%;
    border:unset;
    border-radius: $border-radius-general ;
    background-color: $bg-input-edit;

    .p-treeselect-label-container{

        .p-treeselect-label {
            color: $black-color;
            font-size: 14px;
            padding: 0.5rem;
            white-space: wrap;

            .p-treeselect-token{
                margin: 0.2rem;
                padding: 0.2rem 0.5rem;
                background: $white;
                color: $safflower-red;
                border-radius: $border-radius-general ;

                .p-treeselect-token-label{
                    font-size: 12px;
                    padding: 0.1rem;
                    word-break: normal;
                }
            }
        }
    }
    &:not(.p-disabled):hover { 
        border-color:  $safflower-red;
    }

    &:not(.p-disabled).p-focus {
        box-shadow: unset;
        border-color:  $border-05-color;
    }
}

//Serlect prime react
.p-treeselect-panel{

    input[type=text]{
        background-color: $bg-input-edit!important;
    }

    .p-treeselect-items-wrapper{
        @include insideScrollBehavior ();

        .p-tree {
            padding: 0.5rem 1rem;

            .p-tree-container{

                .p-treenode {
                    padding: 0.143rem 0;

                    &:focus > .p-treenode-content {
                        outline: 0 none;
                        outline-offset: 0;
                        box-shadow: unset;
                    }

                    .p-treenode-content{
                        padding: 0.3rem;

                        .p-treenode-label{
                            font-size: 12px ;
                        } 

                        &:not(.p-highlight):hover {
                            background: $body-color;
                            color: $black-color;
                        }

                        &:focus {
                            outline: 0 none;
                            outline-offset: 0;
                            box-shadow: unset;
                        }

                        .p-tree-toggler:focus {
                            box-shadow: unset ;
                        }
                        
                        &.p-highlight {
                            background:$rose ;
                            color: $black-color ;
                            outline-offset: 0;
                            box-shadow: unset;
                        }
                    }
                }
            }
        }
    }
    
}