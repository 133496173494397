@import "../../scss/index.scss";

.school-course-form {

    .modal-content-wrapper{

        .control-wrapper{
            @include flexbox(100%, row, space-between, center, nowrap);

            .flex-container{
                
                .control-label{
                    width: 100%;   
                }
                
                .control-input{
                    width: 100%;

                    .p-calendar{
                        width: 100%;
                    }
                }
                
            }
        }
    }
}
