@import '../../scss/index.scss';

.change-password{
    @include landingBg($bg-gradient);
    .change-password-box{
        @include flexbox(100%, column, center, center, nowrap);
        height: 100%;
        border-radius: $border-radius-30;
        .logo_link{
            width: 320px
        }
        .box-password-form{
            @include flexbox(auto, column, center, center, nowrap);
            min-width: 270px;
            margin-top: 40px;
            font-size: 0.9rem;
            gap: 0.5rem;

            .title-form{
                padding: 5px 0;
                font-weight: 500;
            }
            
            .text-reset-password{
                color: $black-color;
                font-size: 0.9rem;
                margin-bottom:10px;
            }

            .p-inputtext{
                text-align: center;
                
                &::placeholder {
                    color: $shadow-gray-color;
                }
            }
            
            .btn__with-bg-type-1  {
                width: 100%;
                height: 37px; 
            }
        }
    }
}