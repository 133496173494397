@import "../../../scss/index.scss";

.center-data-table {
    /**scroll table**/
    // max-height: calc(100vh - 243px);
    // overflow: auto;
    // @media only screen and (min-width: $media-xx-large) {
    //     max-height: calc(100vh - 257px);
    // }

    .p-datatable-wrapper {
        .p-datatable-table {
            .p-datatable-tbody {
                tr > td {
                    &:first-child {
                        background: $body-color !important;
                    }

                    // .p-checkbox {
                    //     border-radius: 4px;
                    // }
                    // .p-checkbox .p-checkbox-box {
                    //     border-color: $gray;
                    //     border: 0.5px solid $gray;
                    //     border-radius: 4px;
                    // }

                    // .p-multiselect {
                    //     border: 1px solid $safflower-red;

                    //     .p-multiselect-label-container {
                    //         .p-multiselect-label {
                    //             padding: 0.2rem 0.3rem !important;
                    //             font-size: 12px;
                    //         }
                    //     }
                    // }
                }

                // .editRow {
                //     background-color: $edit-color !important;
                //     .p-checkbox .p-checkbox-box {
                //         background-color: $edit-color !important;
                //     }
                // }

                // .roll-title {
                //     font-weight: 400 !important;
                //     font-size: 12px;
                // }
            }

            // .cell-table {
            //     padding: 0.3rem 0.5rem !important;
            //     height: 40px;
            // }
        }
    }
}
