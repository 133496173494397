@import '../../../../scss/index.scss';

// Input with icon right - login page

.p-password {
    width: 100%;

    .p-password-input {
        background-color: $white;

        &::placeholder {
            text-align: center;
            color: $black-color;
        }

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px $white inset;
            background-color: transparent;

            &:focus {
                -webkit-box-shadow: 0 0 0px 1000px $white inset;
                background-color: transparent;
            }
        }

        .p-input-icon-right > svg {
            top: 47%;
        }
    }

    .p-icon-field {
        width: 100%;

        > .p-input-icon {
            top: 26%;

            .p-icon:focus {
                outline: unset;
            }
        }
    }
}