@import '../../../../scss/index.scss';

.categories-template{

    .item-category-template:first-child{

        .category{

            .name, .description{
                border:transparent !important;
                border-radius: unset !important;
                background-color: transparent !important;
                padding: unset !important;
                margin: unset !important;
            }
        }
    }
    
    .item-category-template{
        
        .category{
            display: flex;
            justify-content: space-between;

            h4{
                font-weight: 400;
                color: $safflower-red;
                
            }

            .name, .description{
                width: 50%;
                border: $border-1p-color;
                border-radius: $border-radius-5;
                background-color: $light-grayish-blue;
                padding: 2px 5px;
                margin: 0 5px 5px 0;
                font-size: 14px;
            }
        }
    }
}

.right-border {
    border-right: 1px solid rgb(244, 247, 248);

    .p-column-header-content {
        justify-content: center;
    }
}

.row-subject {
    text-transform: capitalize;
}