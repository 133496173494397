@import '../../../scss/index.scss';

.form-center {
    .control-wrapper {
        @include flexbox(100%, row, space-between, center, wrap);
        
        .flex-container {
            @include flexbox(100%, column, space-between, center, wrap);
            @media screen and (min-width: $media-medium) {
                    width: 48%;
            }
            .control-label{
                width: 100%;   
            }

            .control-input {
                width: 100%;
                padding-bottom: 0rem;

                //input reestyles
                .input-container{
                    min-width: 100%;
                    .input-control{
                        border: none;
                        height: 37px;
                    }
                }
            }

            .combo-wrapper {
                width: 100%;
                padding-bottom: 0.5rem;
                //selectable
                .p-dropdown {
                    width: 100%; 
                    border: unset !important;
                }
            }
            
        }
    } 

}