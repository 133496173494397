@import '../../../scss/index.scss';

.form-staff-hours{

    .modal-content-wrapper{
        
        .control-wrapper{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            gap: 1rem;
            
            .flex-container:first-child{
                width: 29%;

                .p-inputnumber {
                    width: 100%;
                }
            }
        }
    }
}