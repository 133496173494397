@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

$white: #ffff;
$gray: #dadada;
$shadow-gray-color: #777777;
$table-gray-scroll-color: #a1a1a1;
$color-disabled: #c3c3c3;
$rose: #f5e2e3;
$lightblue: #d5dfe4;
$silver-light: #e5e9f1; //TODO change name
$hover-color: #ddc6c7;
$safflower-red: #da3653;
$dark-shade-pink-red: #8b1a30;
$light-red: #d51628;
$rose-medium: #e7818d;
$black-color: black;
$exports-btn-bg: #dee2e6;
$body-color: #f7f7f5;
$light-grayish-blue: #f8f9fa;
$edit-color: #d9f1f5;
$create-color: #73a6b9;
$bg-dark-color: #d9d7cf;

//TODO delete?
$border-radius: 10px;

// COLOURS
$bg-container: $body-color;
$bg-input: $gray;
$bg-input-edit: $edit-color;
$text-placeholder-color: #6b7280;
$iconos-color: $safflower-red;
$item-setected-color: $rose;
$item-hover-color: $safflower-red;
$item-selected-border-color: $light-red;
$bg-gradient: linear-gradient(to right, $white 0%, $rose-medium 100%);
$bg-gradient-card: linear-gradient(
    90deg,
    rgba(238, 237, 231, 1) 0%,
    rgba(255, 255, 255, 1) 100%
);
$bg-gradient-modal: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 43%,
    rgba(136, 26, 48, 0.9) 100%
);
$filter-white-color: brightness(0) saturate(100%) invert(100%) sepia(6%)
    saturate(171%) hue-rotate(222deg) brightness(108%) contrast(107%);
// PADDING
$padding-container: 1rem;

// SIZE WINDOWS
$min-height-container: calc(100vh - 125px);
$min-height-container-with-pagination: calc(100vh - 210px);

// TEXT
$a-color-text: $black-color;
$color-title-container: $black-color;

// BORDER
$border-radius-general: 10px;
$border-radius-5: 5px;
$border-radius-30: 30px;
$border-1p-color: 1px solid $gray;
$border-1p-black-color: 1px solid $black-color;
$border-2p-white-color: 2px solid $white;
$border-05-color: 0.5px solid $safflower-red;
$border-2p-color-v2: 2px solid $dark-shade-pink-red;
$general-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);

//eliminar
$border-left-menu-selected: 7px solid $item-selected-border-color;

//Fonts
$font: "Poppins", sans-serif !important;
$font-monospace: Consolas, monospace !important;

//Media Queries
$media-xxx-large: 1850; //1600px
$media-xx-large: 1600px; //1400px
$media-large-land: 1400px; //1366px
$media-x-large: 1366px; //1200px
$media-medium-land: 1200px; //1024px
$media-large: 1024px; //992px
$media-medium: 992px; //768px
$media-small-land: 768px; //640px
$media-small: 640px; //576px
$media-x-small: 576px; //450px

//VARIBLES QUE SE USAN EN STYLED COMPONENTS
:export {
    bgInputEdit: $bg-input-edit;
    borderRadius: $border-radius-general;
    textPlaceholderColor: $text-placeholder-color;
    iconosColor: $iconos-color;
    border2pColor: $border-05-color;
    optionHoverColor: $body-color;
    optionCheckBG: $silver-light;
    itemSelectedColor: $item-setected-color;
    optionMultiselectChecked: $rose-medium;
}

$app-component-spacer: 0.5rem;

$app-debug: false;
