@import '../../../scss/index.scss';


.center-logo-wrapper{
    @include flexbox(120px, row, space-between, center);
    position: relative;
    
    .upload-btn-wrapper{
        display: none;

        .btn-label{
            cursor: pointer;
            i{
                background-color: $white;
                padding: 0.5rem;
                border-radius: 5px;
                position: absolute;
            }
        }

        .inputfile{
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }

    .logo-image{
        width: 100%;
        position: absolute;
        top: -18px;
    }

    &:hover{
        .upload-btn-wrapper{
            display: block;
            position: absolute;
            right: 0;
            top: -10px;
        }
    }
}


