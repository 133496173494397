@import '../../../../scss/index.scss';

.p-dropdown {
    font-size: 14px;
    border: unset;
    background: $edit-color;
    border-radius: $border-radius-general;
    width: 100%;

    &:disabled {
        opacity: 0.8;
        color: $black-color;
        border:1px solid $gray;
    }

    &:not(.p-disabled).p-focus {
        box-shadow: none;
        border-color:  $safflower-red;
    }

    .p-dropdown-trigger {
        color:  $safflower-red;
    }

    .p-dropdown-items { 

        .p-dropdown-item {
            color: $black-color ;
            font-size: 12px;
            padding: 0.5rem 1.25rem ;
            
            &:not(.p-disabled):hover {
                background: $bg-container ;
            }

            &.item-active {
                background: $rose ;
            }
            
        }
    }

}

.p-dropdown-panel {
    font-size: 14px;
    border-radius: $border-radius-general;

    .p-dropdown-header {
        border-top-right-radius: $border-radius-general;
        border-top-left-radius: $border-radius-general;

        input[type=text]{
            background-color: $bg-input-edit!important;
        }
    }

    .p-dropdown-items-wrapper{
        @include insideScrollBehavior ();
        .p-dropdown-items { 
            padding: 10px 0;
            .p-dropdown-item {
                color: $black-color ;
                font-size: 12px;
                padding: 0.5rem 1.25rem ;
                
                &:not(.p-disabled):hover {
                    background: $bg-container ;
                }

                &.p-highlight {
                    background: $rose ;
                }

                &.item-active {
                    background: $rose ;
                }
            }
        }
    }
    
}
