@import '../../scss/index.scss';

.profile-form{
    .modal-content-wrapper{
        .control-wrapper:first-child{
            @include flexbox(100%, row, space-between, center, wrap);
            

            .flex-container:first-child{ 
                height: 200px;
                @include flexbox(31%, unset, center, center, nowrap);

                .profile-img{ 
                    height: 100%;
                    overflow: hidden;
                    display: grid;
                    place-content: center;
                    border-radius:$border-radius-general; 

                    img{
                        border-radius:$border-radius-general;
                        object-fit: cover; 
                        object-position: bottom;
                    }
                } 
            }

            .flex-container:last-child{
                flex: 1;
                margin-left: 1rem;
            }
        }

        .control-wrapper:last-child{
            display: flex;
            justify-content: space-between;

            .flex-container{
                width: 31%;

                .control-input{

                    .p-password-input{
                        background-color:$bg-input-edit!important;

                        &::placeholder {
                            text-align: left;
                        }
                    }
                    .input-container {
                        min-width: unset;
                    }
                }
            }
        }
    }
}