@import '../../../scss/index.scss';

.form-management-position{

    .modal-content-wrapper{

        .control-wrapper:first-child{
            display: flex;
            flex-wrap: nowrap;
            

            .flex-container{
                width: 32%;
            }
        }

        .control-wrapper{
            @include flexbox(100%, row, space-between, unset, nowrap);
            margin-bottom: 0.5rem;
            
            .flex-container{
                width: 31%;
            }
        }

        .control-wrapper-grid{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @media screen and (min-width :$media-x-small) {
                grid-template-columns: repeat(4, 1fr);
            }
            
            gap: 0 1rem;
            margin-bottom: 0.5rem;
        }
    }

   
}





