@import "../../scss/index.scss";

.swal-modal {
    background-color: $white;
    padding: 1.5rem;
    border-radius: $border-radius-general;
    width: 90%;
    max-width: 470px;

    .swal2-icon {
        margin: 0.5em auto 0.6em;

        &.swal2-warning {
            border: 4px solid;
        }

        &.swal2-error {
            border: 4px solid;
        }
    }

    .swal2-title {
        color: $safflower-red;
        font-size: 16px;
        text-align: center;
    }

    .swal2-html-container {
        color: $safflower-red;
        font-size: 14px;
        text-align: center;
    }

    .swal2-actions {
        button {
            min-width: 135px;
            padding: 5px 30px;
            font-weight: 400;
            border-radius: $border-radius-general;
            cursor: pointer;
            border: none;
            transition: 0.2s ease all;
            border: 0.5px solid;
            box-shadow: unset !important;

            &:focus {
                box-shadow: none;
            }

            &.swal2-cancel {
                background-color: $white;
                color: $safflower-red;
                border: 1px solid $safflower-red;
                margin-right: 1rem;

                &:not([disabled]):hover {
                    background-color: $safflower-red;
                    color: $white;
                }
            }

            &.swal2-confirm {
                background-color: $safflower-red;
                color: $white;
                border: none;

                &:not([disabled]):hover {
                    background-color: $safflower-red;
                    color: $white;
                }
            }

            &.success {
                background-color: $safflower-red;
                color: $white;
                border: none;

                &:not([disabled]):hover {
                    background-color: $safflower-red;
                    color: $white;
                }
            }
        }

        .swal2-loader {
            border-color: $silver-light rgba(0, 0, 0, 0) $silver-light
                rgba(0, 0, 0, 0);
        }
    }
}
