@import "../../../scss/index.scss";

.p-breadcrumb {
    @if ($app-debug) {
        // *:after {
        //     @extend .app-debug-label;
        // }
        // background: orange !important;
    }
    background: transparent;
    border: unset;
    border-radius: unset;
    padding: 0;

    .p-breadcrumb-list {
        @if ($app-debug) {
            *:after {
                @extend .app-debug-label;
                left: 0 !important;
            }
        }

        align-items: center;
        li {
            height: inherit;
            margin: 0;
            padding: 0;
            a {
                display: inline-flex;
                @if ($app-debug) {
                    background: yellow !important;
                }
            }

            font-size: 14px;
            &:last-child .p-menuitem-text {
                color: $safflower-red;
                font-weight: 500;
            }

            &.p-menuitem-separator {
                margin: 0rem;
                color: $shadow-gray-color;
                visibility: hidden;

                &::after {
                    content: "/";
                    visibility: visible;
                    margin-left: -0.5rem;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}
