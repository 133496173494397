@import '../../scss/index.scss';

.maestros-template-import-form{

    .modal-content-wrapper{

        .content-wrapper{
            @include flexbox(100%, row, space-between, unset, nowrap);
            .flex-container{
                width: 48%;

                .p-fileupload .p-button{
                    padding: 8px;
                    height: 33px;
                }
            }
        }
    }
}