@import '../../../../scss/index.scss';

.flex-container{

    .control-input{

        .input-container{
            padding-bottom: unset;

            .input-control{
                &:disabled{
                    background-color: $white;
                    color: $black-color;
                    border:1px solid $gray;
                    opacity: 0.8;
                }
            }
        }
        .p-inputtext:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px $edit-color inset!important;
            background-color: $edit-color!important;
        }
    
    }
}