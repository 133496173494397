@import "../../../scss/index.scss";

$main-view-content-debug: false;

.main-view-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $media-x-small) and (max-width: $media-medium) {
        @if ($main-view-content-debug) {
            background: green !important;
        }
        margin-bottom: 100px;
    }

    .context-main-view {
        flex-grow: 1;

        .box-item {
            background: $edit-color;

            .box-content {
                border-radius: $border-radius-general;
                border: 1.5px solid $create-color;

                & .red-text {
                    color: $create-color;
                }
            }

            .complete {
                background-color: $hover-color !important;
                border-color: $safflower-red !important;

                & a {
                    color: $safflower-red !important;
                }
            }
        }
    }
}

.summary-data {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .table {
        @include tabview-ccontainer();

        .center-data-table {
            @include tabview-ccontainer();
            flex-grow: 1;
            max-height: calc(100vh - 341px);
            overflow: auto;

            @media only screen and (min-width: $media-xx-large) {
                max-height: calc(100vh - 360px);
            }
            .p-datatable-wrapper {
                .p-datatable-table {
                    .p-datatable-tbody > tr:nth-child(odd) > td {
                        background-color: unset;
                    }
                }
            }
        }
    }

    .summary-footer {
        display: flex;
        justify-content: right;
    }
}
