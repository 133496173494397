.width {
    &__100 {
        width: 100%;
    }
    &__98 {
        width: 98%;
    }
    &__85 {
        width: 85%;
    }
    &__70 {
        width: 70% !important;
    }
    &__65 {
        width: 65%;
    }
    &__55 {
        width: 55%;
    }
    &__50 {
        width: 50% !important;
    }
    &__42 {
        width: 42%;
    }
    &__40 {
        width: 40%;
    }
    &__34 {
        width: 34%;
    }
    &__27 {
        width: 27%;
    }
    &__22 {
        width: 22%;
    }
    &__20 {
        width: 20% !important;
    }
    &__19 {
        width: 19%;
    }
    &__17 {
        width: 17%;
    }
    &__16 {
        width: 16.6%;
    }
    &__15 {
        width: 15%;
    }
    &__10 {
        width: 10%;
    }
    &__5 {
        width: 5%;
    }
    &__2 {
        width: 2%;
    }
}
