@import "../../../scss/index.scss";

.main-exports-content{
    @include flexbox(100%, row, flex-end, center, wrap);
    background: $white;
    padding: 0.5rem;
    border-top-right-radius: $border-radius-general;
    border-top-left-radius: $border-radius-general;

    [type="button"] {
        margin-left: 0.5rem;
    }

    .butons-exports {
        display: flex;
        
    }
}




