@import '../../../scss/index.scss';

.icon-btn-edite{
    @include flexbox(100%, row, flex-end, unset);
    .edit-icon{
        display: inline-block;
        width: 25px;
        cursor: pointer;
        margin-bottom:-25px;
        z-index: 1;
    } 
}