@import '../../scss/index.scss';
.landing{
    @include landingBg($bg-gradient);
    &-wrapper__border{
        @include landingBorder(1px solid $safflower-red, 100%, 10px);
        .btn-enter{
            width: 55px;
            margin-top: 1rem;
            transition: .2s ease all;
            cursor: pointer;
        }
    }
}