@import '../../../scss/index.scss';

.general-structure{
    flex-grow: 1;

    .center-structure-form{

        .control-wrapper{
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            gap: 1rem;

            .flex-container{
                width: 100%;
            }
        }
    }
}