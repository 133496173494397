@import '../../../scss/index.scss';

.form-service-types{
    .modal-content-wrapper{
        
        .control-wrapper{

            .flex-container{
                
                .control-label{
                    width: 100%;
                }
            }
        }
    }
}