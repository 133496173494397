@import '../../../../scss/index.scss';

.general-editor{
    width: 100%;
    height: auto;

    .p-editor-container{

        .p-editor-toolbar {
            padding: 2px;
            background: $body-color;
            border-top-right-radius: $border-radius-general;
            border-top-left-radius: $border-radius-general;
        }

        .ql-snow.ql-toolbar button.ql-active .ql-stroke {
            stroke: black;
        }

        .ql-snow.ql-toolbar button{
            height: 20px;
        }

        .p-editor-content {
            min-height: 40px;
            height: unset;
            border-bottom-right-radius: $border-radius-general;
            border-bottom-left-radius: $border-radius-general;

            .ql-editor {
                background: $bg-input-edit; 
            }
        }
        
    }
}

