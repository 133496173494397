div.app-info {
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: rgba(0 0 0 / 0.6);
    border: 1px solid rgba(255 255 255 / 0.2);
    padding: 40px;
    text-align: right;
    border-radius: 8px;
}
