@import "../../scss/index.scss";

//Modal (general)
.ReactModal__Overlay--after-open {
    z-index: 111;
    background: $bg-gradient-modal;
}

.ReactModal__Body--open {
    overflow: hidden;
    height: 100vh;
}

.ReactModal__Content {
    width: calc(100vw - 6rem) !important;
    // height: calc(100vh - 6rem) !important;
    .general-modal__close {
        top: 1rem;
        right: 1rem;
    }
}

.general-modal,
.dropdown-modal {
    background-color: $white;
    position: absolute;
    height: auto;
    padding: 30px;
    border-radius: $border-radius-general;
    outline: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 992px;

    &__favicon {
        position: absolute;
        top: -45px;
        left: 0;
        img {
            width: 70px;
        }
    }

    &__close {
        width: 30px;
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
        color: $safflower-red;
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .title__container {
        margin-bottom: 0.5rem;
        padding-right: 2.2rem;
        text-align: left;
    }

    //general-height para el scroll
    .modal-content-wrapper {
        @media screen and (max-width: $media-xx-large) {
            @include insideScrollBehavior();
            max-height: 64vh;
        }

        @media screen and (min-width: $media-xx-large) {
            @include insideScrollBehavior();
            max-height: 77vh;
        }

        //tabla dentro de modal
        .p-datatable {
            border-radius: $border-radius-general;
        }

        .control-label {
            padding-bottom: 0.1rem;
        }

        /*input[type=text], textarea {
            background-color: $bg-input-edit!important;
        }*/
    }

    //btn container
    .modal-btn-container {
        @include flexbox(100%, row, flex-end, center);
        margin-top: 0.5rem;
        .btn-save-modal {
            padding: 5px 30px;
            font-weight: 400;
            border-radius: $border-radius;
            background: $safflower-red;
            color: $white;
            cursor: pointer;
        }
    }

    .modal-btn-container button:last-of-type {
        margin-left: 10px;
    }
}

.dropdown-modal {
    padding: 0;
    top: 0;
    left: 0;
    transform: none;

    h2,
    .general-modal__favicon,
    .general-modal__close {
        display: none;
    }
}

.no-courses-notification-modal {
    background-color: $safflower-red;
    color: $white;
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 100% 0% 100% 100%;
    transform: rotate(45deg);
    clear: left;
    outline: none;
    right: 40%;
    top: 20%;
    text-align: center;
    font-size: 2rem;

    .general-modal__favicon {
        display: none;
    }

    .general-modal__close {
        img {
            filter: $filter-white-color;
            transform: rotate(-44deg);
        }
    }

    .no-courses-illustration {
        width: 100%;
        height: 100%;
        padding: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            transform: rotate(-44deg);
            color: $white;
            font-size: 2.5rem;
            line-height: 2.3rem;
            text-align: left;
            font-weight: 500;
        }

        button {
            transform: rotate(-44deg);
        }
    }
}
