@import '../../../../scss/index.scss';
 
.staff-profile-salary-table{
    display: flex;
    flex-direction: column;
        
    @include tabview-ccontainer ();
    
    .p-tabview-panels{
        margin-top: 0.5rem;
        padding: unset;
        border: unset;
        background: transparent;

        .p-tabview-panel {
            
            .control-input{
                .input-container{
                    min-width: unset;
                    padding-bottom: 0rem;
                }
                .p-inputtext {
                    font-size: 12px;
                    background-color: $white;
                }
            }
        }
    }

    //no entiendo donde se usa este estilo
    .edit-icon, .delete-icon {
        display: inline-block;
        width: 25px;
        &:hover{
            cursor: pointer;
        }

        margin-bottom: -45px;
        
    }

    .edit-icon {
        margin-right: 70px;
    }

    .delete-icon {
        margin-right: 30px;
        z-index: 1;
    }
    .p-calendar{
    
        .p-datepicker-trigger {
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
            
        }
    }
}

