@import '../../../../scss/index.scss';

.salary-tables {
    /*height for salary-tables
    */
    .card-items{
        @media screen and (min-width: $media-small-land){
            height: 67vh;
        }
        
        @media screen and (min-width: $media-xx-large){
            height: 78vh;
        }
    }
}