@import '../../../scss/index.scss';

.header-actions {
    display: flex;
    justify-content: right;
    align-items: center;
    row-gap: 0.5em;
    
    @media only screen and (max-width: $media-small) {
        flex-wrap: wrap;
    }

    

    .btn {
        margin-left: 0.5rem; 
    }
}