@import "../../scss/index.scss";

.headerView {
    @if ($app-debug) {
        *:after {
            @extend .app-debug-label;
        }
    }
    padding: $app-component-spacer calc($app-component-spacer * 2) !important;
    .headerView-content {
        position: relative;
        @include flexbox(100%, row, space-between, center, wrap);
        padding: $app-component-spacer;
        background-color: $white;
        border-radius: $border-radius-general;
        .left-aligned-part-of-header {
            display: flex;
            flex-grow: 1;
        }

        .title {
            &__container {
                text-align: left;
            }
            &__subtitle {
                padding-right: 1rem;
            }
        }
        .right-aligned-part-of-header {
            display: flex;
            justify-content: right;
            align-items: center;
            row-gap: 1em;
            @media only screen and (max-width: $media-small) {
                flex-wrap: wrap;
            }

            .sort {
                margin-right: 1rem;
                @include flexbox(auto, row, flex-start, center, wrap);
                @media only screen and (max-width: $media-small) {
                    margin-right: 0;
                }

                .combo-label {
                    padding: 0 1em;
                    font-size: 14px;
                    margin: 0 auto;
                }

                .dropdown {
                    .p-dropdown {
                        width: 100%;
                    }
                }
            }

            .search {
                width: auto;
            }

            .btn {
                margin-left: $app-component-spacer;
            }

            /*select btn*/
            .p-dropdown {
                width: 120px;
            }
        }
    }
}
