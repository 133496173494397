@import '../../scss/index.scss';

.react-pdf__Page__annotations.annotationLayer,
.react-pdf__Page__textContent.textLayer {
    display: none;
}

.pdf-modal{
    .preview-file {
        @include insideScrollBehavior ();
        max-height: 63vh;
        @media screen and (min-width: $media-xx-large) {
            max-height: 70vh;
        }

        .loading {
            color: $light-red;
            text-align: center;
            font-size: 1.5rem;
        }
    }

    .modal-btn-container{
        font-size: 14px;
        margin-top: 1rem;

        .btn-save-modal {
            margin-left:0.5rem;
            padding: 5px 15px;

            .pi:before {
                color:$white !important;
                font-size: 1rem;
            }
        }
    }
    
}



