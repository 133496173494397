@import '../../../scss/index.scss';

.subtypes-form{

    .modal-content-wrapper{

        .control-wrapper{

            .flex-container{
                
                .p-chips-multiple-container{
                    padding: 0.55em 0.6em!important; 

                    .p-chips-input-token {
                        padding: unset;
                    }
                }
            }
        }
    }
}
