@import '../../../../scss/index.scss';

.staff-basic-edit-form {
    
    .modal-content-wrapper{
        @media screen and (max-width: $media-xx-large){
            @include insideScrollBehavior ();
            height: 62vh;
        }

        @media screen and (min-width: $media-xx-large){
            @include insideScrollBehavior ();
            height: 75vh;
        }
    }

    @include tabview-ccontainer ();
    
    .p-tabview {
        .p-tabview-nav-container {
            margin-top: 0rem!important;
            padding: 0.5rem 0rem!important;
        }

        .p-tabview-panels {
            padding: 0rem;
        }
    }

    .basic-staff-profile {
        .staff-info-card{
            grid-template-columns: repeat(5,1fr);

            .flex-container{

                .p-dropdown {
                    background-color:$bg-input-edit !important;
                
                }
            }
        }

        .staff-info-card:nth-child(3) {
            @media only screen and (max-width: 1600px){
                grid-template-columns: repeat(5, 1fr);
            }
        }
    }

    .staff-profile-working {
        grid-template-columns: repeat(5,1fr);
    }
}