@import '../../scss/index.scss';

.login{
    @include landingBg($bg-gradient);
    
    .login-box{
        @include flexbox(100%, column, center, center, nowrap);
        height: 100%;

        &__img{
            width: 240px
        }

        .box-login-form{
            @include flexbox(auto, column, center, center, nowrap);
            min-width: 270px;
            margin-top: 40px;
            font-size: 0.9rem;
            gap: 0.5rem;

            .title-form{
                padding: 5px 0;
                font-weight: 500;
            }  

            .p-inputtext {
                text-align: center;
                background-color: $white;
                
                &::placeholder {
                    color: $shadow-gray-color;
                }
            }

            input:-internal-autofill-selected {
                background-color: $white!important;
                -webkit-box-shadow: 0 0 0px 1000px $white inset;
            }

            .p-filled{
                background-color: $white!important;
            }

            .btn__with-bg-type-1  {
                width: 100%;
                height: 37px;
            }

            .reset-password__link{
                color: $black-color;
                font-size: 12px;
                display: inline-block;
                transition: .2s ease all;

                &:hover{
                    color: $safflower-red;
                }
            }
        }
    }
}





