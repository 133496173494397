@import '../../../scss/index.scss';

.p-chips {
    width: 100%;
    
    .p-chips-multiple-container {
        background-color: $bg-input-edit;
        gap: 0.3rem;

        .p-chips-token {
            padding: 0.2rem 0.5rem ;
            background: $white;
            color: $safflower-red;
            border-radius: 8px;
            margin-right: unset;
        
        }

        .p-chips-input-token input {
            font-size: 14px;
            color: $black-color;
        }

        &:hover {
            border-color: $gray!important;
            box-shadow: unset ;
        }

        &.p-focus{
            border-color: $gray;
            box-shadow: unset ;
        }
        
    }
}
.p-chips{
    &:not(.p-disabled):hover .p-chips-multiple-container {
        border-color: unset;
    }

    &:not(.p-disabled).p-focus .p-chips-multiple-container {
        outline: 0 none;
        outline-offset: 0;
        box-shadow:  unset;
        border-color: unset;
    }
}
