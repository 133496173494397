@import '../../../../scss/index.scss';

//delete after refact.
.p-multiselect {

    width: 100%;
    border-radius: 10px ;
    border: unset;
    background-color: $edit-color;

    .p-multiselect-label-container {

        .p-multiselect-label {
            padding: 0.47rem ;
            font-size: 14px ;
            color: $black-color;
            white-space: wrap;

            .p-multiselect-token {
                padding: 0.2rem 0.4rem;
                margin-right: unset;
                background: $body-color;
                color: $safflower-red;
                border-radius:$border-radius-general;
                font-size: 14px;
                margin: 0.3rem ;
            }
        }
    }

    &.p-focus {
        box-shadow: unset;
    }
}

.p-multiselect-panel {

    .p-multiselect-header 
        {input[type=text]{
            background-color: $bg-input-edit;
        }

        .p-multiselect-filter-container{
            border-radius: 10px ;
        }
    }

    .p-multiselect-items-wrapper {
        //scroll style
        @include insideScrollBehavior ();

        .p-multiselect-items {
            padding: 10px 0;

            .p-multiselect-empty-message{
                font-size: 12px;
            }
            
            .selected-item {
                color: $black-color;
                background: $rose;
            }

            .p-multiselect-item {
                padding: 0.5rem 1.25rem;
                color: $black-color;
                font-size: 12px;
                

                &:focus{
                    box-shadow: unset;
                }

                &:hover {
                    background: $body-color; 
                }
            }
            
            .p-highlight {
                    
                background: $rose;
            }
        }
    }
}
