@import "../../../scss/index.scss";

.footer-buttons {
    @if ($app-debug) {
        :is(*):after {
            @extend .app-debug-label;
        }
    }
    background: $white;
    margin: 0 $app-component-spacer;
    @media screen and (max-width: $media-medium) {
        padding: $app-component-spacer $app-component-spacer
            calc($app-component-spacer * 2) $app-component-spacer;
        position: fixed;
        bottom: 0;
        right: $app-component-spacer;
        left: 0;
    }

    @media screen and (min-width: $media-medium) {
        margin: 0;
        padding: 0.5rem 0.5rem 1rem 0.5rem;
        position: fixed;
        bottom: 0rem;
        right: 0.5rem;
        left: 265px;
    }

    &__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &.only-prev {
            justify-content: flex-start;
        }

        &.only-next {
            justify-content: flex-end;
        }
    }
}
