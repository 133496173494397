@import '../../../scss/index.scss';
 
.hours-exceded-form{
 
    &-wrapper{
        display: flex;
        flex-direction: column;
 
        .content-wrapper{
            @include flexbox(auto, row, space-between, unset, wrap);
            
            .flex-container{
                width: 49%;
            }
        }
 
        .table-wrapper{
            @include flexbox(auto, row, space-between, unset, nowrap);
            gap: 1rem;
        }

        .flex-wrapper{
            width: 100%;
            
            .p-column-header-content{
                .p-icon {
                    width: 0.8rem;
                    height: 0.8rem;
                }
            }
 
            .p-datatable{
                border-radius: $border-radius-general;

                .p-datatable-table{
                    font-size: 12px;
                    .p-datatable-thead tr > th {
                        padding: 0.3rem 1rem;
                    }

                    .p-datatable-tbody tr > td {
                        word-break: break-word !important;
                        padding: 0.3rem 1rem!important;

                        .p-inputtext {
                            padding: 0.2rem 0.3rem;
                            font-size: 12px;
                        }

                        .btn-icon{

                            img{
                                width: 14px;
                                height: 14px;
                            }
                        }

                        .p-row-editor-init {
                            width: 1rem;
                            height: 1rem;
                            .p-icon {
                                width: 1rem;
                                height: 0.8rem;
                            }
                        }

                        .p-row-editor-save, .p-row-editor-cancel{
                            width: 1rem;
                            height: 1rem;
                        }
                    }

                    .p-datatable-emptymessage{
                        font-size: 12px!important;
                    }
                }
            }
        }
    }

    .button-white{
        padding: 5px 30px;
    }
}