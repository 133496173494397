@import "../../../scss/index.scss";

.foundation-data{
    position: absolute;
    top: 150px;
    left: 287px;
    width: calc(100% - 314px);
    &__container{
        
        &--data{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;
            @media screen and (min-width: $media-large-land) {
                grid-template-columns: repeat(6, 1fr);
            }
        }
    }
}

