@import '../../../scss/index.scss';

.form-center-cost-modal {

    .modal-content-wrapper{
        
        .control-wrapper{
            
            .flex-container{
                
                .control-input{ 
                    width: 100%;
                } 
            }  
        }
    }
}
