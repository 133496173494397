//Display flex with basic properties
@mixin flexbox(
    $width: 100%,
    $direction: row,
    $jc: space-between,
    $ai: center,
    $wr: wrap,
    $ac: normal
) {
    display: flex;
    width: $width;
    flex-direction: $direction;
    justify-content: $jc;
    align-items: $ai;
    flex-wrap: $wr;
    align-content: $ac;
}
@mixin landingBg($color) {
    width: 100%;
    height: 100vh;
    padding: 20px;
    background: $color;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@mixin landingBorder($border, $height, $bRadius) {
    height: $height;
    border-radius: $bRadius;
    border: $border;
    @include flexbox(100%, column, center, center);
}

@mixin asideHeight($minHeight, $mediaMin) {
    @media screen and (min-width: $media-medium) {
        min-height: $minHeight;
        @media screen and (min-height: $mediaMin) {
            min-height: calc(100vh - 168px);
        }
    }
}

/***********************************/
/******* Grid media cards **********/
/***********************************/
@mixin gridMediaCard() {
    opacity: 1;
    width: 100%;
    display: grid;
    gap: 0.7rem;
    grid-template-columns: repeat(1, 1fr);
    //z-index: 9;
    position: relative;
    @media only screen and (min-width: $media-x-small) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (min-width: $media-small) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media only screen and (min-width: $media-medium-land) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media only screen and (min-width: $media-xx-large) {
        grid-template-columns: repeat(7, 1fr);
    }
}
@mixin boxItem($height) {
    height: $height;
}

@mixin boxContentHoverIcons($height) {
    .box-item {
        @include boxItem($height);
    }
}
@mixin boxContentNoHoverIcons($height) {
    .box-item {
        @include boxItem($height);
        &:hover {
            box-shadow: unset;
        }
    }
}
//GLOBAL MIXIN TO INCLUDE IN COMPONENT (PASS THE TYPE: 'icons' or '')
@mixin gridMediaBoxCard($type: "noicons", $height) {
    @include gridMediaCard();
    @if $type == "icons" {
        @include boxContentHoverIcons($height);
    } @else {
        @include boxContentNoHoverIcons($height);
    }
}

//Cards confirmados
@mixin boxContentComplete() {
    .complete {
        background-color: $hover-color;
        border-color: $safflower-red;
        position: relative;
        a {
            background-color: $hover-color;
            color: $safflower-red;
        }
        &::after {
            content: url("../assets/imgs/check.svg");
            position: absolute;
            top: 5px;
            right: 5px;
            width: 20px;
        }
    }
}

/***********************************/
/***** End Grid media cards ********/
/***********************************/

//Steps component
@mixin stepsComponent() {
    &__container {
        margin-bottom: 1rem;

        ol {
            flex-wrap: wrap;
            .p-steps-item {
                margin: 0rem 0;
                &::before {
                    border: 1.5px solid $edit-color;
                    background-color: $edit-color;
                    width: 50%;
                    top: 60%;
                }

                &::after {
                    content: "";
                    top: 60%;
                    right: 0;
                    display: block;
                    position: absolute;
                    margin-top: -1rem;
                    background: $edit-color;
                    border: 1.5px solid $edit-color;
                    width: 50%;
                }

                .p-steps-number {
                    min-width: 1.5rem;
                    height: 1.5rem;
                    line-height: 2rem;
                    font-size: 1rem;
                    background: $edit-color;
                    color: transparent;
                }

                &.p-steps-current {
                    &::before {
                        border: 1.5px solid $safflower-red;
                        top: 60%;
                    }
                    .p-steps-number {
                        background: $safflower-red;
                        color: transparent;
                    }
                    .p-steps-title {
                        color: $safflower-red;
                        font-weight: 600;
                    }
                    &::after {
                        border: 1.5px solid $safflower-red;
                        top: 60%;
                    }
                }

                &.p-disabled {
                    &::after {
                        content: "";
                        top: 60%;
                        right: 0;
                        display: block;
                        position: absolute;
                        margin-top: -1rem;
                        background: $safflower-red;
                        height: 3px;
                        width: 100%;
                    }
                }

                .p-menuitem-link {
                    .p-steps-number {
                        opacity: 1;
                    }

                    .p-steps-title {
                        font-size: 14px;
                        padding: 0 0.5rem;
                    }
                }
            }
        }
    }
}

//Tooltips
@mixin tooltip() {
    @media screen and (min-width: $media-small) {
        &::after {
            content: attr(data-tooltip);
            position: absolute;
            background-color: $white;
            color: $safflower-red;
            bottom: -14px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0px 8px;
            border: $border-05-color;
            border-radius: 7px;
            z-index: 10;
            font-size: 13px;
            font-weight: 400;
            opacity: 0;
            transition: ease all 0.2s;
        }
        &::before {
            content: "";
            bottom: -10px;
            height: 0;
            left: 50%;
            position: absolute;
            width: 0;
            transform: translateX(-50%);
            position: absolute;
            border: 8px solid transparent;
            border-top: 0;
            border-bottom: 8px solid $safflower-red;
            opacity: 0;
            transition: ease all 0.1s;
        }

        &:hover::after {
            opacity: 1;
            bottom: -20px;
        }

        &:hover::before {
            opacity: 1;
            bottom: 0px;
        }
    }
}

//Exports buttons
//To do: eliminar despues de refactorizar

@mixin exports-button-wrapper {
    .exports-wrapper-content {
        @include flexbox(100%, row, flex-end, center, wrap);
        padding: 0px 1rem;
        margin-bottom: 0.5rem;

        &.with-background {
            @include flexbox(100%, row, flex-end, center, wrap);

            [type="button"] {
                margin-left: 1rem;
            }
        }

        [type="button"] {
            margin-left: 1rem;
        }
    }
}

//Header Wrapper
//To Do:eliminar despues de refactorizar
@mixin header-wrapper {
    @include flexbox(100%, row, space-between, center, nowrap);
    padding: 1rem;
    background-color: $white;
    border-radius: $border-radius-general;
    margin-bottom: 0.5rem;

    .title-heder-wrapper {
        @include flexbox(auto, row, flex-start, center, nowrap);

        .title__subtitle {
            margin-right: 3rem;
        }
    }

    .actions-header-wrapper {
        @include flexbox(auto, row, flex-end, center, nowrap);

        .p-dropdown {
            font-size: 14px;
            border: $border-05-color;
            background-color: $white;
            width: 150px;
        }

        button {
            margin-left: 0.5rem;
        }
    }

    .no-actions {
        @include flexbox(100%, row, flex-start, center, wrap);
        gap: 1rem;
        @media screen and (min-width: $media-medium) {
            width: 465px;
        }

        .actions-select {
            width: 150px;
            @media screen and (min-width: $media-medium) {
                width: 150px;
            }
        }
    }
}

//General Container
@mixin general-container {
    @include flexbox(100%, column, unset, unset, wrap);
    height: 100%;
    flex-grow: 1;
    padding: 0.5rem;
    background-color: $white;
    border-radius: $border-radius-general;
    gap: 0.5rem;

    @media screen and (min-width: $media-medium) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &__aside {
        width: 100%;

        &__header {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            .favicon {
                width: 30px;
            }
        }

        @media screen and (min-width: $media-medium) {
            width: 250px;
            height: 100%;
            padding: 0 0.5rem;
            border-right: 3px solid $body-color;
        }

        @include asideHeight(438px, 730px);
        &.showHideGeneralData {
            @include asideHeight(560px, 810px);
        }
        &.showHideManagementTeam {
            @include asideHeight(705px, 990px);
        }
        &.showHidePastoralTeam {
            @include asideHeight(695px, 990px);
        }
        &.showHideResponsible {
            @include asideHeight(705px, 990px);
        }
        &.showHideAMPA {
            @include asideHeight(600px, 875px);
        }
        &.showHideRltTeam {
            @include asideHeight(705px, 960px);
        }

        & .list {
            margin: 0;
            padding-top: 0.5rem;

            .item-list {
                display: flex;
                align-items: center;
                list-style: none;
                padding: 5px 8px;
                color: $black-color;
                margin-bottom: 0.3rem;
                font-size: 14px;
                font-weight: 400;
                transition: 0.1s ease all;
                cursor: pointer;

                .aside-icon {
                    width: 30px;
                }

                &.close {
                    pointer-events: none;
                    color: $color-disabled;
                }

                &:hover {
                    color: $item-hover-color;
                    outline: none;

                    /*img{
                        filter:$filter-white-color;
                    }*/
                }

                /*&.react-tabs__tab--selected {
                    color: $white;
                    background:$item-hover-color!important;
                    border-radius: $border-radius-general;
                    outline: none;
                    img{
                        filter:$filter-white-color;
                    }
                }*/
            }
            .active {
                color: $item-hover-color !important;
            }
        }
        .aside-no-courses-illustration {
            /* background-color:$safflower-red;
            color: $white;
            width: 140px;
            height: 140px;
            margin-left: 0.5rem;
            border-radius: 100% 0% 100% 100%;
            transform: rotate(45deg);
            clear: left;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;*/

            h2 {
                /* transform: rotate(-44deg);
                text-align: center;
                color: $white;*/
                font-size: 1.2rem;
                font-weight: 400;
            }
        }
    }

    &__section {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 0.5rem;

        .steps-component {
            @include stepsComponent();
        }

        & > .container {
            height: 100%;
            & > .react-tabs__tab-panel.react-tabs__tab-panel--selected {
                height: 100%;
            }
        }
    }

    /*
        & > .container {

            //GENERALDATAPANEL
            .general-data-panel {
                opacity: 1;
                &__title {
                    text-align: center;
                    margin-bottom: 1rem;
                    .title__container{
                        display: inline-block;
                        padding-bottom: 3px;
                        border-bottom: 1.5px solid $safflower-red;
                    }
                }
                &__tipology {
                    margin-bottom: 1rem;
                    .text {
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
                &__data {
                    background-color: $white;
                    border-radius: 10px;
                    border: 1px solid $color-disabled;
                    margin-bottom: 1rem;
                    padding-top: 1rem;
                    position: relative;
                    .dades-center__container--actions {
                        position: absolute;
                        top: 5px;
                        right: 5px;
                    }
                }

                .table-wrapper {
                    //min-height: calc(100vh - 530px);
                    @include flexbox(100%, column, space-between, flex-start);
                    @media screen and (min-width: $media-medium) {
                        min-height: calc(100vh - 480px);
                    }
                    .p-datatable.table-component {
                        width: 100%;
                        .p-datatable-table {
                            .p-datatable-tbody {
                                max-height: auto;
                                @media screen and (min-width: $media-medium) {
                                    max-height: calc(100vh - 140px);
                                }
                            }
                        }
                    }
                }

                .list-statistics__bottom-actions-wrapper {
                    margin-top: 1rem;
                    @include flexbox(100%, row, space-between, flex-start);
                    .mutate-actions {
                        display: flex;
                        .btn:last-of-type {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }

            //MANAGEMENTTEAMPANEL
            .management-team-panel {
                opacity: 1;
                .table-wrapper {
                    //min-height: calc(100vh - 300px);
                    @media screen and (min-width: $media-medium) {
                        min-height: calc(100vh - 310px);
                    }

                    .flex-container {
                        @include flexbox(auto, column, space-between, flex-start);
                        margin-top: 1.5rem;
                        @media screen and (min-width: $media-medium) {
                            min-height: calc(100vh - 682px);
                        }

                        .text-area-container {
                            //min-height: 150px;
                            width: 100%;
                            .text {
                                margin: 0.5rem 0;
                            }
                            .p-editor-content {
                                //min-height: 150px;
                                .ql-editor {
                                    min-height: 150px;
                                }
                            }
                        }

                        .list-statistics__bottom-actions-wrapper {
                            margin-top: 1rem;
                            @include flexbox(100%, row, space-between, flex-start);
                            .mutate-actions {
                                display: flex;
                                .btn:last-of-type {
                                    margin-left: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }

            //PASTORALTEAMPANEL
            .pastoral-team-panel {
                .table-wrapper {
                    //min-height: calc(100vh - 300px);
                    @media screen and (min-width: $media-medium) {
                        min-height: calc(100vh - 310px);
                    }

                    .flex-container {
                        @include flexbox(auto, column, space-between, flex-start);
                        margin-top: 1.5rem;
                        @media screen and (min-width: $media-medium) {
                            min-height: calc(100vh - 671px);
                        }

                        .text-area-container {
                            //min-height: 150px;
                            width: 100%;
                            .text {
                                margin: 0.5rem 0;
                            }
                            .p-editor-content {
                                //min-height: 150px;
                                .ql-editor {
                                    min-height: 150px;
                                }
                            }
                        }

                        .list-statistics__bottom-actions-wrapper {
                            margin-top: 1rem;
                            @include flexbox(100%, row, space-between, flex-start);
                            .mutate-actions {
                                display: flex;
                                .btn:last-of-type {
                                    margin-left: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }

            //RESPONSIBLEPANEL
            .responsible-panel {
                .table-wrapper {
                    //min-height: calc(100vh - 300px);
                    @media screen and (min-width: $media-medium) {
                        min-height: calc(100vh - 310px);
                    }

                    .flex-container {
                        @include flexbox(auto, column, space-between, flex-start);
                        margin-top: 1.5rem;
                        @media screen and (min-width: $media-medium) {
                            min-height: calc(100vh - 682px);
                        }

                        .text-area-container {
                            width: 100%;
                            .text {
                                margin: 0.5rem 0;
                            }
                            .p-editor-content {
                                .ql-editor {
                                    min-height: 150px;
                                }
                            }
                        }

                        .list-statistics__bottom-actions-wrapper {
                            margin-top: 1rem;
                            @include flexbox(100%, row, space-between, flex-start);
                            .mutate-actions {
                                display: flex;
                                .btn:last-of-type {
                                    margin-left: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }

            //AMPAPANEL
            .ampa-panel {
                &__field {
                    margin-bottom: 2rem;
                    .flex-container {
                        @include flexbox(100%, row, flex-start, flex-start);
                        .text-label {
                            margin-right: 1rem;
                        }
                    }
                }

                .table-wrapper {
                    @media screen and (min-width: $media-medium) {
                        min-height: calc(100vh - 365px);
                    }

                    .flex-container {
                        @include flexbox(auto, column, space-between, flex-start);
                        margin-top: 1.5rem;
                        @media screen and (min-width: $media-medium) {
                            min-height: calc(100vh - 579px);
                        }

                        .text-area-container {
                            //min-height: 150px;
                            width: 100%;
                            .text {
                                margin: 0.5rem 0;
                            }

                            .p-editor-content {
                                //min-height: 150px;
                                .ql-editor {
                                    min-height: 150px;
                                }
                            }
                        }

                        .list-statistics__bottom-actions-wrapper {
                            margin-top: 1rem;
                            @include flexbox(100%, row, space-between, flex-start);
                            .mutate-actions {
                                display: flex;
                                .btn:last-of-type {
                                    margin-left: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }

            //RLTTEAMPANEL
            .rlt-team-panel {
                .table-wrapper {
                    @media screen and (min-width: $media-medium) {
                        min-height: calc(100vh - 365px);
                    }

                    .flex-container {
                        @include flexbox(auto, column, space-between, flex-start);
                        margin-top: 1.5rem;
                        @media screen and (min-width: $media-medium) {
                            min-height: calc(100vh - 682px);
                        }

                        .text-area-container {
                            //min-height: 150px;
                            width: 100%;
                            .text {
                                margin: 0.5rem 0;
                            }
                            .p-editor-content {
                                //min-height: 150px;
                                .ql-editor {
                                    min-height: 150px;
                                }
                            }
                        }

                        .list-statistics__bottom-actions-wrapper {
                            margin-top: 1rem;
                            @include flexbox(100%, row, space-between, flex-start);
                            .mutate-actions {
                                display: flex;
                                .btn:last-of-type {
                                    margin-left: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }*/
}

//eliminar

@mixin header-logo-box($with, $height: auto) {
    .logo-wrapper {
        @include flexbox(100%, row, center, center);
        position: relative;
        border-radius: 6px;
        .upload-btn-wrapper {
            display: none;
            .btn-label {
                cursor: pointer;
                i {
                    background-color: $white;
                    padding: 0.5rem;
                    border-radius: 5px;
                }
            }

            .inputfile {
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;
            }
        }

        .logo-image {
            width: $with;
            height: $height;
            object-fit: contain;
            border-radius: 5px;
        }
        &.with-margin {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
    &:hover {
        .upload-btn-wrapper {
            display: block;
            position: absolute;
            bottom: 4px;
            left: 4px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

//general scroll behavior
@mixin insideScrollBehavior() {
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(161, 161, 161, 0.6);
        background-color: $white;
        border-radius: $border-radius-general;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: $gray;
        height: 6px;
        border-radius: $border-radius-general;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(161, 161, 161, 0.6);
        border-radius: $border-radius-general;
    }
}

@mixin input-spin-btn() {
    //input spinner inside the modal
    .input-container {
        position: relative;
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            width: 2rem;
            border-left: 1px solid $gray;
            opacity: 1;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }
        &::after {
            color: $safflower-red;
            content: "\25B2";
            width: 12px;
            top: 0.5em;
        }

        &::before {
            color: $safflower-red;
            width: 12px;
            content: "\25BC";
            margin-bottom: 5px;
            bottom: 0.5em;
        }
    }

    .input-container:after,
    .input-container:before {
        position: absolute;
        right: 5px;
        width: 1.6em;
        height: 0.9em;
        font-size: 10px;
        pointer-events: none;
    }
}

//grid for staff profile
@mixin box-container-grid() {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media only screen and (min-width: $media-small-land) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media only screen and (min-width: $media-xx-large) {
        grid-template-columns: repeat(8, 1fr);
    }
    gap: 0 0.5rem;

    @include box-container-style();

    .flex-container {
        justify-content: flex-end;
    }
}

@mixin box-container-style() {
    background-color: $white;
    border-radius: $border-radius-general;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

//tab view import in component
@mixin tabview-ccontainer() {
    .p-tabview {
        .p-tabview-nav-container {
            margin-top: 0.5rem;
            background: $white;
            padding: 0.5rem;
            border-radius: $border-radius-general;
        }

        .p-tabview-nav {
            border-width: 0px 0px 1px 0px;
            flex-wrap: wrap;
            row-gap: 0.5rem;

            li {
                margin-right: 1rem;
            }

            .p-tabview-nav-link {
                background: transparent;
                color: $safflower-red;
                font-weight: 400;
                padding: 0 1rem 0.5rem 0;
            }

            & li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
                border-color: $hover-color;
            }

            & li.p-highlight .p-tabview-nav-link {
                background: transparent;
                font-weight: 500;
                border-color: $safflower-red;
            }

            .p-tabview-ink-bar {
                background-color: $safflower-red;
            }
        }
    }

    //tree-table
    .p-tabview-panels {
        padding: 0.5rem;
        border: 0 none;
        color: $black-color;
        border-bottom-right-radius: $border-radius-general;
        border-bottom-left-radius: $border-radius-general;

        .p-tabview-panel {
            .p-accordion {
                .p-accordion-header {
                    .p-accordion-header-link {
                        padding: 0.5rem;
                        color: $black-color;
                        font-size: 14px;
                        background: $body-color;
                        font-weight: 500;
                        border-radius: $border-radius-general;
                        transition: box-shadow 0.2s;
                    }
                }

                .p-toggleable-content {
                    .p-accordion-content {
                        padding: 0.5rem;
                        background: $white;
                        color: $black-color;
                        border-bottom-right-radius: $border-radius-general;
                        border-bottom-left-radius: $border-radius-general;
                    }
                }
            }

            .p-treetable {
                .p-treetable-table {
                    .p-treetable-thead {
                        > tr > th {
                            font-size: 12px;
                            padding: 0.5rem;
                            font-weight: 500;
                            color: $black-color;
                            background: $body-color;
                        }

                        > tr {
                            > th:first-child {
                                border-top-left-radius: $border-radius-general;
                            }
                            > th:last-child {
                                border-top-right-radius: $border-radius-general;
                            }
                        }
                    }
                    .p-treetable-tbody {
                        > tr > td {
                            padding: 0.5rem;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
