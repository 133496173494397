@import "../../scss/index.scss";

.box-item-icon:hover {
    box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.4);
}

.box-item-icon {
    @include flexbox(100%, column, center, center, nowrap);

    min-height: 100px !important;
    background-color: $white;
    box-shadow: 0 6px 6px -6px $shadow-gray-color;
    position: relative;
    border-radius: $border-radius-general;
    transition: 0.2s ease all;

    .order-card {
        pointer-events: none;
        position: absolute;
        left: 10px;
        top: 10px;
        background-color: $safflower-red;
        color: $white;
        padding: 2px 10px;
        font-size: 12px;
        border-radius: 4px;
    }

    .card-img {
        width: 80px;
        height: auto;
    }

    .box-content {
        @include flexbox(100%, column, center, center, nowrap);
        border-radius: $border-radius-general;
        border: 1.5px solid $safflower-red;
        height: 100%;
        padding: 0.5rem;
        background-color: $white !important;

        .red-text {
            width: 100%;
            background-color: $white;
            color: $safflower-red;
            font-weight: 500;
            text-align: center;
            transition: 0.1s ease-in-out all;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
