@import "../../scss/index.scss";

.foundacion-container{
    padding: 0.5rem 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    .foundation-course-tabs{
        
    }
}
