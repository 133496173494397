@import '../../../../scss/index.scss';


.p-calendar {
    
    .p-datepicker-trigger {
        border-top-right-radius: $border-radius-general;
        border-bottom-right-radius: $border-radius-general;
        &.p-button {
            margin-bottom: unset;
            padding: unset;
            background-color:$safflower-red;
            .p-icon{
                color: $white;
            }
        }
    }
    .p-inputtext {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: unset;
    }

    &:not(.p-calendar-disabled).p-focus > .p-inputtext {
        box-shadow: unset;
    }
}

.p-datepicker {
    font-size: 12px;
    @media screen and (min-width: $media-xx-large) {
        font-size: 14px;
    }

    .p-datepicker-header{
        .p-datepicker-title {
            line-height: unset;

            .p-link{
                font-size: 14px;
            }
            .p-monthpicker-month.p-highlight {
                color: $safflower-red;
                background: $rose
            }

            .p-datepicker-month:enabled:hover, .p-datepicker-year:enabled:hover {
                color: $safflower-red;
                font-size: 14px;
            }
        }
    }

    .p-monthpicker .p-monthpicker-month.p-highlight {
        color: $safflower-red;
                background: $rose;
    }
    .p-yearpicker .p-yearpicker-year.p-highlight {
        color: $safflower-red;
        background: $rose;
    }

    .p-datepicker-calendar-container{
        
        .p-datepicker-calendar{
            margin: 0;

            thead{
                th{
                    font-size: 14px;
                    padding: 0.3rem;
                }
            }

            tbody{
                td{
                    font-size: 14px;
                    padding: 0.3rem;

                    &> span {
                        width: 2rem;
                        height: 2rem;
                        &:focus {
                            box-shadow: 0 0 0 0.2rem $rose;
                        }
                    }

                    &> span.p-highlight {
                        color:  $black-color;
                        background: $rose;
                    }
                }
            }
            
        }

    } 
    
    button{
        width: auto;
        height: auto;
    }

    .p-yearpicker {
        span[data-p-highlight=true] {
            color: $safflower-red;
            background: $rose;
        }
    }
}

.p-datepicker:not(.p-disabled){
    .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
        box-shadow:   unset;
    }
    .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
        box-shadow:   unset;
    }
}
