@import '../../../scss/index.scss';
.new-post-centerteam-modal{
    .modal-content-wrapper{
        .control-wrapper{
            .control-input{
                width: 100%; 
            }
            
            .p-multiselect {
                margin-bottom: 0.5rem;
            }
        }
    }
}
