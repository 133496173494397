@import '../../../../../scss/index.scss';

.foundation-account-plan {
    .p-datatable {

        .p-datatable-wrapper{
            .p-treeselect{
                min-height: 40px;
                margin-top: 4px;
            }
        }
    }
}