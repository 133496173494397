@import '../../../scss/index.scss';

.posts {
    padding: 0rem 1rem;
    &__header-wrapper {
        @include flexbox(100%, row, space-between, center);
        margin-bottom: 0.5rem;
        
        .title__container{
            font-weight: 400;
        }
        
    }

    .p-datatable{
        border-radius: $border-radius-general;
    }
}