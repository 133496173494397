@import '../../../scss/index.scss';

//FormGeneralAccount.jsx
.form-account {
    &.new-account-modal {
       
        .modal-content-wrapper{
            .flex-container{
                
                .control-input{
                    width: 100%;
                } 
            }
        }
    }
}