@import '../../../scss/index.scss';

.accounting-plan-details {
    @include flexbox(100%, column, flex-start, unset, unset);


    .header-accounting-plan{
        display: flex;
        flex-direction: row;
        gap:1rem;
        padding: 0.5rem;
        background-color: $white;
        margin: 0.5rem 0;
        border-radius: $border-radius-general;
        @media only screen and (width <= $media-small-land){
            flex-direction: column;
        }

        &__content{
            max-width: 48%;

            @media only screen and (width <= $media-small-land){
                max-width: 100%;
            }
        }
    }

    .box-container-style{
        @include box-container-style ();
        @include flexbox(100%, row, normal, unset, nowrap);
        gap: 1rem;
        @media only screen and (width <= $media-small-land){
            flex-direction: column;
        }

        .flex-container:first-child{
            min-width: 25%;
        }

        .box-container-content{
            @include flexbox(auto, row, normal, unset, nowrap);
            gap: 1rem;


            .flex-container{
                
                .p-multiselect{
                    .p-multiselect-label-container{
                        display: flex;
                        flex-wrap: wrap;

                        .p-multiselect-label{
                            max-height: 20vh;
                            @include insideScrollBehavior();
                        }
                    }
                }

                .sense-items{
                    font-size: 12px;
                }
            }

            .flex-container:first-child{
                min-width: 20%;
            }
        }
        
        .type-activity{
            min-width: 25%;
        }

        .type-activity-list{
            @include flexbox(auto, row, normal, unset, wrap);
            gap: 1rem;
        }
    }
}
