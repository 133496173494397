@import '../../../scss/index.scss';

.collective-add-file{
    
    .label-action{
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .label-action-info{
            display: flex;
            gap:0.5rem;
            justify-content: space-between;

            .flex-container:first-child{
                width: 40%;
            }
            
            .flex-container:nth-child(2){
                width: 55%;
            }

        }
    }

    .p-fileupload-choose {
        overflow: hidden;
        
        .p-button-label {
            text-overflow: ellipsis;
        }
    }

    .btn-action-file{
        display: flex;
        justify-content: flex-end;
        margin-top: 0.2rem;

        .button-white{
            padding: 5px 30px;
        }
    }
}
