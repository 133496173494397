@import "../../../scss/index.scss";

//editable-totals-table

.editable-totals-table {
    @if ($app-debug) {
        // :is(*):after {
        //     @extend .app-debug-label;
        // }
    }
    border-radius: $border-radius-general;
    overflow: auto;

    .p-datatable-loading-overlay {
        padding-top: 70px;
        background: rgba(255 255 255 / 0.6);
    }

    .p-datatable-wrapper {
        .p-datatable-table {

            .p-datatable-tbody {
                tr {
                    td {
                        input[type="text"] {
                            background-color: $white !important;
                            // padding: 0.1rem 0.3rem;
                            // font-size: 12px;
                        }
                    }
                    td:not(.editRow, .p-editable-column) {
                        &:first-child {
                            background: $body-color !important;
                        }
                    }

                    .p-editable-column {
                        background-color: inherit !important;
                    }

                    .editRow {
                        background-color: $edit-color !important;

                        .p-dropdown {
                            width: auto;
                            border: $border-05-color;
                            .p-inputtext {
                                // padding: 0.2rem 0.3rem;
                                // font-size: 12px;
                            }
                        }

                        .p-calendar {
                            width: auto;
                        }
                    }

                    .cell-footer:not(.editRow){
                        background-color: $body-color !important;
                    }

                    .cell-footer {
                        font-weight: 600;
                    }
                }


                .read-only {
                    background-color: $white !important;
                }
            }

            .p-datatable-tfoot {
                > tr > td {
                    padding: 0.5rem;
                    border: unset;
                    border-width: unset;
                    font-weight: 500;
                    color: $black-color;
                    background: $body-color;
                }
            }

            .cell-table {
                padding: 0.3rem 0.5rem !important;
                height: 40px;
            }

            .p-calendar {
                .p-inputtext {
                    border: 1px solid $safflower-red;
                }
            }
        }
    }
}

// .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr .p-editable-column {
//     background-color: inherit !important;
// }

// .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr .editRow {
//     background-color: $edit-color !important;
// }

// .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr .cell-footer:not(.editRow){
//     background-color: rgb(165, 255, 231) !important;
//     font-weight: 500;
// }
