@import '../../../scss/index.scss';

.collective-agreement-form {

    .modal-content-wrapper{
        @include flexbox(100%, column, space-between, unset, wrap);
        
        .control-wrapper{
            @include flexbox(100%, row, space-between, unset, nowrap);
            .flex-container{
                width: 50%;
            }
            gap: 0.5rem;
        }

        .content-label{
            @include flexbox(100%, row, space-between, unset, nowrap);
            gap: 0.5rem;

            @media screen and (min-width: $media-small) {
                 
            }

            .files-data{
                @include flexbox(auto, column, space-between, unset, nowrap);
                    flex-grow: 1;
            }
        }
    }

    .p-fileupload .p-button{
        padding: 6px;
        
        .p-button-label{
            word-break: keep-all;
            height: 20px;
        }
        
        .p-icon{
            width: 2rem;
        }
    }

    .collective-table{
        width: 100%;
        @media screen and (min-width: $media-small) {
            width: 50%;
            @include insideScrollBehavior ();
            max-height: 63vh;
        }
        
        .p-column-header-content{
            .p-icon {
                width: 0.8rem;
                height: 0.8rem;
            }
        }

        .p-datatable {
            border-radius: $border-radius-general;
                .p-datatable-table{
                    font-size: 12px !important;

                    .p-datatable-thead tr > th {
                        padding: 0.3rem 0.5rem !important;
                    }

                    .p-datatable-tbody tr > td {
                        word-break: keep-all!important;
                        padding: 0.3rem 0.5rem!important;

                        .p-calendar{
                            .p-inputtext {
                                width: 3.2rem;
                            }
                        }

                        .p-button-icon-only {
                            width: 1.5rem;

                            .p-icon {
                                width: 0.8rem;
                            }
                        }

                        .p-fileupload .p-button {
                            padding: 8px;
                            height: 27px;
                            font-size: 12px;
                            
                            .p-icon {
                                width: 0.7rem;
                            }
                        }

                        a{
                            display: flex;
                            align-items: center;
                            i{
                                margin-left: 0.3rem;
                            }
                        }

                        .p-inputtext {
                            padding: 0.2rem 0.3rem;
                            font-size: 12px !important;
                        }

                        .btn-icon{

                            img{
                                width: 14px;
                                height: 14px;
                            }
                        } 

                        .p-row-editor-init {
                            width: 1rem;
                            height: 1rem;
                            .p-icon {
                                width: 1rem;
                                height: 0.8rem;
                            }
                        }

                        .p-row-editor-save, .p-row-editor-cancel{
                            width: 1rem;
                            height: 1rem;
                        }
                        
                    }

                    .p-datatable-emptymessage{
                        font-size: 12px!important;
                    }
                }
            }
        }
       
    
}