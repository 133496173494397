
.flex-container {
    
    .p-fileupload {
        .p-button {
            padding: 0.8rem 1.5rem;

            .p-button-label {
                font-size:  12px;
            }
        }
    }
    
}