// primeicons
.pi {
    font-family: "primeicons" !important;
    color: $safflower-red;
}

.p-icon {
    color: $safflower-red;
}

.pi-pencil:before  {
    content: url('../../imgs/editar.svg');
    background-repeat: no-repeat;
    width: 1.8rem;
} 

.p-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: unset;
}