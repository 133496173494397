@import "../../../scss/index.scss";

$header-courses-content-min-height: 33px;

.header-courses {
    @if ($app-debug) {
        // :is(div):after {
        //     @extend .app-debug-label;
        // }
    }

    @include flexbox(100%, row, space-between, center, nowrap);

    padding: $app-component-spacer;
    background-color: $white;
    border-radius: $border-radius-general;
    margin-bottom: $app-component-spacer;

    @media screen and (max-width: $media-small-land) {
        flex-wrap: wrap;
    }

    .title-course-header {
        @include flexbox(auto, row, flex-start, center, nowrap);
        min-height: $header-courses-content-min-height;

        .title__subtitle {
            @if ($app-debug) {
                background: violet;
            }
            display: inline-flex;
            align-items: center;
            min-height: $header-courses-content-min-height;
            width: 240px;
            font-weight: 500;
        }
    }

    .actions-course-header {
        @if ($app-debug) {
            background: cyan;
        }
        @include flexbox(100%, row, flex-end, center, wrap);
        @media screen and (max-width: $media-small-land) {
            margin-top: $app-component-spacer;
        }

        @media screen and (min-width: $media-medium) {
            // width: auto;
        }
        .p-dropdown {
            font-size: 14px;
            border: $border-05-color;
            background-color: $white;
            width: 150px;
            .p-inputtext {
                padding: 5px;
                background-color: $white;
            }
        }

        button {
            margin-left: $app-component-spacer;
        }
    }

    .no-actions {
        @include flexbox(100%, row, flex-start, center, wrap);
        gap: 1rem;
        @media screen and (min-width: $media-medium) {
            width: 465px;
        }

        .actions-select {
            width: 150px;
            @media screen and (min-width: $media-medium) {
                width: 150px;
            }
        }
    }
}

.huge-combo {
    font-size: 14px;
}
