@import '../../../scss/index.scss';

//FormGeneralEtapa.jsx
.form-etapa{

    .modal-content-wrapper{

        .control-wrapper{
            @include flexbox(100%, row, space-between, center, nowrap);
            gap: 1rem;

            .control-input-check{
                
                .control-input{
                    height: 37px;
                }
            }
        }
        
        .control-wrapper:first-child{
            .flex-container{
                flex-grow: 1;
            }

            .flex-container:nth-child(2){
                width: 10%;
            }
        }
    }
}