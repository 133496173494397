.margin {
    &__5 {
        margin: 5px 0;
    }
    &__10 {
        margin-bottom: 10px;
    }
    &__2 {
        margin-top: 2px;
    }
    &__8 {
        margin-left: 8px;
    }
    &.top {
        margin-top: 10px;
    }
}
.mr {
    &__10 {
        margin-right: 10px;
    }
    &__0 {
        margin-right: 0;
    }
}
.mr-0 {
    margin-right: 0 !important;
}

.mt {
    &__10 {
        margin-top: 10px;
    }
}
.mb {
    &__10 {
        margin-bottom: 10px;
    }
}
