@import "../../../scss/index.scss";

.main-layout {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $media-x-small) {
        // padding-bottom: 21px;
        height: 100vh;
        @if ($app-debug) {
            background: blueviolet;
        }
        padding-bottom: 0;
        margin-bottom: 0;
    }
    @media screen and (min-width: $media-x-small) {
        // padding-bottom: 21px;
        height: calc(100vh - $app-main-header-height-medium);
        @if ($app-debug) {
            background: silver;
        }
    }
    @media screen and (min-width: $media-medium) {
        // padding-bottom: $app-main-header-height-large;
        height: calc(100vh - $app-main-header-height-medium);
        @if ($app-debug) {
            background: gray;
        }
    }
    @media screen and (min-width: $media-large-land) {
        // padding-bottom: $app-main-header-height-large;
        height: calc(100vh - $app-main-header-height-large);
        @if ($app-debug) {
            background: black;
        }
    }
}
