@import '../../../scss/index.scss';

.form-salary{

    .wrapper-flex{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @media screen and (min-width :$media-small-land) {
            grid-template-columns: repeat(3, 1fr);
        }
        gap: 0 1rem;
        padding-right: 10px;
        
    }

    .wrapper-flex{

        .flex-container:first-child {
            grid-column: 1/4;
            
            .control-label{
                text-align: center;
            }
        }
    }
}