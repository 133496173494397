@import '../../../scss/index.scss';

.categories-form {
    .modal-content-wrapper{
        
        .control-wrapper{
            @include flexbox(100%, row, space-between, center, nowrap);
            gap: 0.5rem;
            
            .flex-container:nth-child(2){
                width: 80%;
            }
        }
    }
}