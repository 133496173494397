@import "../../../scss/index.scss";

.legend {
    margin: 0 $app-component-spacer;
    position: fixed;
    bottom: 0;
    padding-bottom: 57px;
    right: $app-component-spacer;

    @media screen and (max-width: $media-medium) {
        left: 0;
        background: $white;
    }

    @media screen and (min-width: $media-medium) {
        left: 265px;
    }

    .legend-wrapper {
        @include flexbox(100%, row, flex-end, center);
        padding: $app-component-spacer;

        .legend-list {
            @include flexbox(100%, row, flex-end, center);
            margin: 0;

            &__item {
                margin-right: 2.5rem;
                position: relative;
                font-size: 14px;

                &:last-of-type {
                    margin-right: 0;
                }
                .icon {
                    color: $safflower-red;
                    background-color: $hover-color;
                    border-color: $safflower-red;
                    position: relative;
                    &::after {
                        content: url("../../../assets/imgs/check.svg");
                        position: absolute;
                        bottom: -5px;
                        right: 5px;
                        width: 20px;
                    }
                }
                &:last-of-type::before {
                    content: "";
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    background-color: $white;
                    border: 1px solid $safflower-red;
                    position: absolute;
                    top: 50%;
                    left: -8px;
                    transform: translate(-15px, -50%);
                }
            }
        }
    }
}
