@import '../../scss/index.scss';

.p-fileupload{    
    
    .p-button {
        width: 100%;
        padding: 8px 25px;
        height: 37px;
        border-radius:$border-radius-general;
        border: 0.5px solid $safflower-red;
        color:$safflower-red;
        background: $white;
        font-size: 14px;
        margin-bottom: unset;
        
        .p-button-label{
            width: fit-content;
            overflow: hidden;
            text-overflow: ellipsis !important;
            word-break: keep-all;
            height: 20px;
        
        }

        .p-icon{
            width: 2rem;
        }
    }
}